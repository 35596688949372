import { Add, Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';
import CustomButton from '../custom-button';

type ToggleButtonProps = {
  children: ReactNode;
  selected?: boolean;
  onColor?: string;
  offColor?: string;
  onBackgroundColor?: string;
  offBackgroundColor?: string;
  customOnIcon?: ReactElement;
  customOffIcon?: ReactElement;
  showIcon?: boolean;
  closable?: boolean;
  hasError?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  onToggle: () => void;
};
const ToggleButton: FC<ToggleButtonProps> = ({
  children,
  selected = false,
  customOnIcon = <Close fontSize='large' />,
  customOffIcon = <Add fontSize='large' />,
  showIcon = false,
  closable = false,
  hasError = false,
  fullWidth = false,
  disabled = false,
  onToggle,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <CustomButton
        sx={{
          pointerEvents: closable && selected ? 'none' : 'auto',
        }}
        inverted={selected}
        hasError={hasError}
        onClick={onToggle}
        fullWidth={fullWidth}
        disabled={disabled}
      >
        {showIcon && (selected ? customOnIcon : customOffIcon)} {children}
      </CustomButton>
      {closable && selected && (
        <IconButton
          onClick={onToggle}
          sx={{
            position: 'absolute',
            top: '-18px',
            right: '-18px',
            borderRadius: '50%',
            transform: 'scale(.5)',
            border: '2px currentColor solid',
            backgroundColor: '#fff',
            ':hover': {
              backgroundColor: '#f1f1f1',
            },
          }}
        >
          <Close fontSize='small' />
        </IconButton>
      )}
    </Box>
  );
};

export default ToggleButton;
