import MenuIcon from '@mui/icons-material/Menu';
import { Box, IconButton } from '@mui/material';
import ContextLink from 'components/_base/ContextLink';
import { useAuthenticated, useIsTempPatient } from 'hooks';
import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Logo from 'resources/images/logo.svg';
import { openLeftDrawer } from 'store/actions/navMenu.actions';

const HeaderLeftBloc: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuthenticated();
  const isTempPatient = useIsTempPatient();

  const handleDrawerOpen = useCallback(() => {
    dispatch(openLeftDrawer());
  }, [dispatch]);

  return (
    <>
      {isAuthenticated ? (
        <>
          {!isTempPatient && (
            <IconButton
              onClick={handleDrawerOpen}
              sx={{
                marginRight: { xs: '0px', sm: '32px' },
                display: { sm: 'flex' },
                ...(isOpen && { display: 'none' }),
              }}
            >
              <MenuIcon sx={{ fontSize: 24 }} />
            </IconButton>
          )}
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: { xs: 'center', md: 'initial' },
              alignItems: 'center',
            }}
            display='flex'
          >
            <ContextLink to='HOME'>
              <Box component='img' alt='MyLab_Logo' src={Logo} sx={{ height: '24px' }} />
            </ContextLink>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: { xs: 'center', md: 'initial' },
            alignItems: 'center',
          }}
          display='flex'
        >
          <ContextLink to='HOME'>
            <Box component='img' alt='MyLab_Logo' src={Logo} />
          </ContextLink>
        </Box>
      )}
    </>
  );
};

export default HeaderLeftBloc;
