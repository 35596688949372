// NL2BR https://kevinsimper.medium.com/react-newline-to-break-nl2br-a1c240ba746
const Nl2br = (props: { text: string }) => {
  return props.text.split('\n').map((line, index) => {
    return (
      <span key={index}>
        {line}
        <br />
      </span>
    );
  });
};

export default Nl2br;
