import { Box, Typography } from '@mui/material';
import ToggleButton from 'components/buttons/toggle-button';
import { intlFormat } from 'date-fns';
import { useIsMobileView } from 'hooks';
import { Rdv } from 'models/appointment.model';
import { AppointmentSlot } from 'models/appointment.slots.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getUTCDate } from 'utils/func/Appointment.func';

type SlotProps = {
  time: string;
  selected?: boolean;
  disabled?: boolean;
  onSelect: () => void;
};

const Slot: FC<SlotProps> = ({ time, selected, disabled, onSelect }) => {
  return (
    <Box width={'calc( 25% - 6.33px )'}>
      <ToggleButton selected={selected} disabled={disabled} onToggle={onSelect} fullWidth={true}>
        {intlFormat(getUTCDate(new Date(time)), { hour: 'numeric', minute: 'numeric' }, { locale: 'fr-fr' })}
      </ToggleButton>
    </Box>
  );
};

type BookingSearchSlotPickerProps = {
  rdv: Rdv;
  selectedDate: Date | null;
  startDate: Date;
  endDate: Date;
  availableSlotsForSelectedDate: AppointmentSlot[];
  slotsOpen?: boolean;
  selectSlot: (slot: string) => void;
};

const BookingSearchSlotPicker: FC<BookingSearchSlotPickerProps> = ({ rdv, selectedDate, startDate, endDate, availableSlotsForSelectedDate, slotsOpen = true, selectSlot }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  return (
    <Box
      flex='1 1 50%'
      boxShadow='inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A'
      borderRadius='10px'
      sx={
        isMobileView
          ? {}
          : {
              opacity: slotsOpen ? 1 : 0,
              transition: 'opacity 0.5s ease-in-out',
              pointerEvents: slotsOpen ? 'all' : 'none',
            }
      }
    >
      {!selectedDate ? (
        <Typography padding={2} fontWeight='500' textAlign='center'>
          {t('appointment.book.steps.search.date.no_selection')}
        </Typography>
      ) : selectedDate < startDate || selectedDate > endDate ? (
        <Typography padding={2} fontWeight='500' textAlign='center'>
          {t('appointment.book.steps.search.date.please_select')}
        </Typography>
      ) : (
        <Box display='flex' flexWrap='wrap' gap={1} m={2}>
          {availableSlotsForSelectedDate.length < 1 ? (
            <Typography padding={2} fontWeight='500' textAlign='center'>
              {t('appointment.book.steps.search.date.no_slot')}
            </Typography>
          ) : (
            <>
              {/* @see #5 Shift first slot if it's not on the hour */}
              {new Date(availableSlotsForSelectedDate[0].start).getMinutes() !== 0 && <Box width={'calc( 25% - 6.33px )'} />}
              {availableSlotsForSelectedDate.map((slot) => (
                <Slot key={slot.start} time={slot.start} selected={rdv.slot === slot.start} disabled={slot.nbrSlot < 1 || slot.closed} onSelect={() => selectSlot(slot.start)} />
              ))}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BookingSearchSlotPicker;
