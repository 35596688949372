export default {
  environment: process.env.NODE_ENV,
  myLabApiUrl: process.env.REACT_APP_MYLAB_API_URL,
  myRdvApiUrl: process.env.REACT_APP_MYRDV_API_URL,
  myRdvApiUser: process.env.REACT_APP_MYRDV_API_USERNAME,
  myRdvApiPassword: process.env.REACT_APP_MYRDV_API_PASSWORD,
  myRdvBaseUrl: process.env.REACT_APP_MYRDV_BASE_URL,
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    clientSecret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
    grantType: process.env.REACT_APP_AUTH_GRANT_TYPE,
  },
  header: {
    doctorPriorityNumber: process.env.REACT_APP_MYLAB_PRIORITY_PATIENT_NUMBER || '-',
    priorityNumber: process.env.REACT_APP_MYLAB_PRIORITY_PATIENT_NUMBER || '-',
  },
  footer: {
    contactUsEmail: process.env.REACT_APP_MYLAB_CONTACT_US_EMAIL || 'helpdesk@bionext.lu',
    doctorPriorityNumber: process.env.REACT_APP_MYLAB_PRIORITY_DOCTOR_NUMBER || '-',
    priorityNumber: process.env.REACT_APP_MYLAB_PRIORITY_PATIENT_NUMBER || '-',
  },
  ePrescription: {
    apiUrl: process.env.REACT_APP_ESANTE_API_URL,
    clientId: process.env.REACT_APP_ESANTE_API_CLIENT_ID,
    clientSecret: process.env.REACT_APP_ESANTE_API_CLIENT_SECRET,
  },
  // Bionext website
  websiteBionextApiUrl: process.env.REACT_APP_WEBSITE_BIONEXT_API_URL,
  // Google maps
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  // Stripe
  stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY,
  // Mobile apps
  myLabAppiOS: process.env.REACT_APP_MYLAB_APP_IOS || 'https://apps.apple.com/us/app/mylab/id1242297692?l=fr&ls=1',
  myLabAppAndroid: process.env.REACT_APP_MYLAB_APP_ANDROID || 'https://play.google.com/store/apps/details?id=lu.bionext.app',
  appointmentNumber: process.env.REACT_APP_MYLAB_APPOINTMENT_NUMBER || '-',
};
