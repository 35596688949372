import { Dialog, DialogProps } from '@mui/material';
import { FC } from 'react';

const CustomDialog: FC<DialogProps> = (props) => {
  return (
    <Dialog
      {...props}
      PaperProps={{
        ...props.PaperProps,
        sx: {
          ...props.PaperProps?.sx,
          borderRadius: '10px',
          boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A',
        },
      }}
      slotProps={{
        ...props.slotProps,
        backdrop: {
          ...props.slotProps?.backdrop,
          sx: {
            backgroundColor: 'transparent',
            backdropFilter: 'blur(2px)',
          },
        },
      }}
      maxWidth='md'
    />
  );
};

export default CustomDialog;
