import { Box, Step, StepLabel, Stepper } from '@mui/material';
import api from 'api';
import ConnectAccountDialog from 'components/connect-account-dialog';
import { useAuthenticated, useContextRedirection, useCurrentUser, useCurrentUserRelatives, useIsDoctor, useIsMobileView, useLocale, useNotification } from 'hooks';
import { Appointment, AppointmentPatient, Rdv } from 'models/appointment.model';
import { AppointmentSlots } from 'models/appointment.slots.model';
import { Patient } from 'models/patients.model';
import { Prescription } from 'models/prescription.model';
import { Address, Gender } from 'models/profile.model';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import RoutePaths from 'utils/RoutePaths';
import { canMakeAppointmentForPatient } from 'utils/func/Appointment.func';
import BookingIntroductionStep from './components/Booking.introduction.step';
import BookingPatientsInfoStep from './components/Booking.patients.info.step';
import BookingPrescriptionStep from './components/Booking.prescriptions.step';

export type IntroductionStep = 'choose-patient-number' | 'choose-patients' | 'choose-at-center';
export type AddressType = 'home' | 'work';
export type MemoizedSlots = Map<string, AppointmentSlots>;

export type Props = {
  selectablePersons: SelectablePerson[];
  state: Appointment;
  rdv: Rdv;
  atCenter?: boolean | null;
  isDoctor: boolean;
  introductionStep: IntroductionStep;
  patientHomeAddress?: Address;
  patientWorkAddress?: Address;
  selectedPlace: google.maps.places.AutocompletePrediction | null;
  selectedAddressType: AddressType | null;
  memoizedSlots: MemoizedSlots;
  selectedDate: Date | null;
  handleNext: () => void;
  handleState: <T extends keyof Appointment>(toAdd: Appointment[T], field: T) => void;
  setRdv: React.Dispatch<React.SetStateAction<Rdv>>;
  handleBack: () => void;
  setAtCenter: React.Dispatch<React.SetStateAction<boolean | null>>;
  setIntroductionStep: React.Dispatch<React.SetStateAction<IntroductionStep>>;
  setSelectedPlace: React.Dispatch<React.SetStateAction<google.maps.places.AutocompletePrediction | null>>;
  setSelectedAddressType: React.Dispatch<React.SetStateAction<AddressType | null>>;
  setMemoizedSlots: React.Dispatch<React.SetStateAction<MemoizedSlots>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
};

export type SelectablePerson = AppointmentPatient & {
  id: number;
  name: string;
};

const Preregistration: FC<{
  hasComponent?: boolean;
  activeStepNoComponent?: number;
  setActiveStepNoComponent?: React.Dispatch<React.SetStateAction<number>>;
  setDataNoComponent?: any;
  offline?: boolean;
}> = ({ hasComponent, activeStepNoComponent = 0, setActiveStepNoComponent = () => {}, offline = false }) => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();
  const relatives = useCurrentUserRelatives();
  const isDoctor = useIsDoctor();
  const [atCenter, setAtCenter] = useState<boolean | null>(null);
  const isAuthenticated = useAuthenticated();
  const { id: patientId, prescId } = useParams();
  const [patientDetails, setPatientDetails] = useState<Patient>();
  const [selectablePersons, setSelectablePersons] = useState<SelectablePerson[]>([]);
  const isMobileView = useIsMobileView();
  const navigate = useContextRedirection();
  const [activeStep, setActiveStep] = useState(0);
  const [prescription, setPrescription] = useState<Prescription>();
  const { notification } = useNotification();
  const locale = useLocale();
  const [state, setState] = useState<Appointment>({
    creator: undefined,
    comment: '',
    slot: '',
    address: {
      type: isAuthenticated ? 'home' : 'other',
      number: '',
      street: '',
      complement: '',
      city_name: '',
      company: '',
      zip_code: '',
      country_iso: '',
    },
    patients: [],
    prescription_id: undefined,
  });

  const [rdv, setRdv] = useState<Rdv>({
    coordInfo: null,
    slot: null,
    center: null,
    prescriptions: [],
  });

  useEffect(() => {
    if (prescId) {
      api.prescriptions
        .getPrescriptionByBasketId(prescId)
        .then((response) => {
          setPrescription(response);
        })
        .catch(() => {
          notification(t('prescriptions_making.step2.yourSelection.notification.errorWhenLoadingPrescription'), 'error');
          navigate(RoutePaths['NOT_FOUND']);
        });
    }
  }, [prescId, notification, t, navigate]);

  const handleState = useCallback<<T extends keyof Appointment>(toAdd: Appointment[T], field: T) => void>((toAdd, field) => {
    setState((prevstate) => ({
      ...prevstate,
      [field]: toAdd,
    }));
  }, []);

  const handleNext = useCallback(() => {
    let stepToUse: number, setStepToUse: (value: React.SetStateAction<number>) => void;
    if (hasComponent) {
      stepToUse = activeStep;
      setStepToUse = setActiveStep;
    } else {
      stepToUse = activeStepNoComponent;
      setStepToUse = setActiveStepNoComponent;
    }
    setCompleted((completed) => {
      const c = [...completed];
      c[stepToUse] = true;
      return c;
    });
    setStepToUse((activeStep) => (activeStep >= steps.length - 1 ? activeStep : activeStep + 1));
    // eslint-disable-next-line
  }, [activeStep, activeStepNoComponent, hasComponent, setActiveStep, setActiveStepNoComponent]);

  const handleBack = useCallback(() => {
    if (isDoctor && activeStep === 0) navigate(`/patients/${patientDetails?.id}`);
    else if (hasComponent) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      setActiveStepNoComponent((prevActiveStep: number) => prevActiveStep - 1);
    }
    setCompleted((completed) => {
      const c = [...completed];
      c[activeStep - 1] = false;
      return c;
    });
  }, [hasComponent, setActiveStepNoComponent, activeStep, isDoctor, patientDetails?.id, navigate]);

  // Temporary states used to manage form steps
  const [introductionStep, setIntroductionStep] = useState<IntroductionStep>('choose-at-center');
  const [selectedPlace, setSelectedPlace] = useState<google.maps.places.AutocompletePrediction | null>(null);
  const [selectedAddressType, setSelectedAddressType] = useState<AddressType | null>(null);
  const [memoizedSlots, setMemoizedSlots] = useState<MemoizedSlots>(new Map());
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const steps: { title: string; component: JSX.Element }[] = useMemo(() => {
    const props = {
      state,
      rdv,
      atCenter,
      setAtCenter,
      isDoctor,
      patientHomeAddress: isDoctor ? patientDetails?.addresses.home : currentUser?.addresses.home,
      patientWorkAddress: isDoctor ? patientDetails?.addresses.work : currentUser?.addresses.work,
      selectablePersons,
      introductionStep,
      selectedPlace,
      selectedAddressType,
      memoizedSlots,
      selectedDate,
      handleState,
      setRdv,
      handleNext,
      handleBack,
      setIntroductionStep,
      setSelectedPlace,
      setSelectedAddressType,
      setMemoizedSlots,
      setSelectedDate,
      setActiveStep,
    };
    const stepper = [
      {
        title: t('appointment.book.steps_labels.introduction'),
        component: <BookingIntroductionStep {...props} />,
      },
      {
        title: t('appointment.book.steps_labels.patients_info'),
        component: <BookingPatientsInfoStep {...props} />,
      },
      {
        title: t('appointment.book.steps_labels.prescriptions'),
        component: <BookingPrescriptionStep {...props} />,
      },
    ];
    return stepper;
  }, [
    state,
    handleState,
    handleBack,
    handleNext,
    rdv,
    atCenter,
    setAtCenter,
    isDoctor,
    patientDetails?.addresses.home,
    patientDetails?.addresses.work,
    currentUser?.addresses.home,
    currentUser?.addresses.work,
    selectablePersons,
    t,
    introductionStep,
    selectedPlace,
    selectedAddressType,
    memoizedSlots,
    selectedDate,
  ]);

  const [completed, setCompleted] = useState<boolean[]>(new Array(steps.length).fill(false));
  useEffect(() => {
    if (isDoctor && patientId) {
      api.patients
        .getPatientDetails(parseInt(patientId))
        .then((patient) => {
          if (!canMakeAppointmentForPatient(patient)) {
            notification(t('appointment.tooltip.incompleteProfil'), 'warning');
            navigate(RoutePaths['DOCTOR_PATIENTS_DETAILS'](patientId));
          }
          setPatientDetails(patient);
          const _patient = {
            patient_id: patient.id,
            cns: patient.cns,
            birth_date: patient.birth_date,
            birth_name: patient.birth_name,
            gender: patient.gender === Gender.MALE ? Gender.MALE_FULL : Gender.FEMALE_FULL,
            last_name: patient.last_name,
            first_name: patient.first_name,
            phone: patient.phone,
            mobile_phone: patient.mobile_phone,
            email: patient.email,
          };
          setState((prev) => ({
            ...prev,
            prescription_id: prescription?.id,
            patients: [
              {
                ..._patient,
                patient_id: `${_patient.patient_id}`,
                fasting: prescription?.fasting ?? false,
                // As we use the same type definition for preregistration and appointment,
                // we need to keep this field even if it's not used
                medical_acts: [],
                prescriptions: [],
                comment: undefined,
              },
            ],
          }));
        })
        .catch(() => notification(t('appointment.book.steps.patients.notification.error'), 'error'));
    }
  }, [isDoctor, notification, patientId, currentUser, t, navigate, hasComponent, offline, handleState, prescription, locale]);

  useEffect(() => {
    let list: SelectablePerson[] = [];
    if (currentUser !== undefined) {
      list = relatives
        ? relatives.map((relative) => ({
            name: `${relative.last_name} ${relative.first_name}`,
            id: relative.id,
            patient_id: `${relative.id}`,
            cns: relative.cns,
            birth_date: relative.birth_date ?? undefined,
            birth_name: relative.birth_name,
            gender: relative.gender === Gender.MALE ? Gender.MALE_FULL : Gender.FEMALE_FULL,
            last_name: relative.last_name,
            first_name: relative.first_name,
            phone: relative.phone ?? undefined,
            mobile_phone: relative.mobile_phone ?? undefined,
            email: relative.email ?? undefined,
            fasting: false,
            medical_acts: [],
            prescriptions: [],
            comment: undefined,
          }))
        : [];
    }
    setSelectablePersons(list);
    // setIntroductionStep(list.length > 0 ? 'choose-patients' : 'choose-patient-number');
    setIntroductionStep('choose-at-center');
  }, [relatives, currentUser]);

  return (
    <Box display='flex' flexDirection='column' mt={4} flex={1}>
      {activeStep === 0 && !isAuthenticated && introductionStep === 'choose-at-center' && (
        <Box display='flex' my={4} justifyContent='center'>
          <ConnectAccountDialog redirect={RoutePaths['PREREGISTRATION']} />
        </Box>
      )}
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={index} completed={completed[index]}>
              <StepLabel color='inherit'>{isMobileView ? '' : step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {((selectablePersons.length > 0 && (isDoctor ? state.patients.length > 0 : true)) || !isAuthenticated) && (
        <Box display='flex' alignSelf='center' flex={1} width={isMobileView ? '100%' : '80%'} px={isMobileView ? 2 : 0} overflow='hidden' position='relative'>
          {steps[activeStep].component}
        </Box>
      )}
    </Box>
  );
};

export default Preregistration;
