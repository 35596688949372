import { FC, ReactNode } from 'react';
import CustomButton from '../custom-button';
import { ButtonProps } from '@mui/material';

type RoundButtonProps = ButtonProps & {
  children?: ReactNode;
  visible?: boolean;
  selected?: boolean;
  onClick: () => void;
};

const RoundButton: FC<RoundButtonProps> = ({ children, visible = true, selected = false, onClick, ...props }) => {
  const sx = {
    borderRadius: '50%',
    width: '64px',
    height: '64px',
    padding: 0,
    borderColor: selected ? '#46BFF5' : 'currentColor',
    whiteSpace: 'nowrap',
    fontWeight: '500',
    color: selected ? '#ffffff' : 'initial',
    backgroundColor: selected ? '#46BFF5' : 'initial',
    ':hover': {
      borderColor: '#ffffff',
      backgroundColor: '#46BFF5',
    },
    ':disabled': {
      borderColor: 'rgba(0,0,0,.24)',
    },
    ...props.sx,
  } satisfies ButtonProps['sx'];

  return (
    visible && (
      <CustomButton
        inverted={selected}
        sx={sx}
        onClick={onClick}
      >
        {children}
      </CustomButton>
    )
  );
};

export default RoundButton;
