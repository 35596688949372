import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import i18n from 'i18n';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONST_WHATSNEW_FLAG } from 'utils/Constants';
import { GlobalState } from './store/reducers';

export const useAuthenticated = () => {
  return useSelector((state: GlobalState) => state.session.isAuthenticated);
};

export const useDisclaimerNeeded = () => {
  return useSelector((state: GlobalState) => state.session.authenticateUser?.is_disclaimer_needed);
};

export const useId = <T extends object = any>(suffix?: string) => {
  return useCallback((id: keyof T) => (suffix ? `${String(id)}-${suffix}` : id), [suffix]);
};

export const useToggle = (defaultValue = false): [boolean, () => void] => {
  const [value, setValue] = useState(defaultValue);

  const toggle = useCallback(() => {
    setValue((val) => !val);
  }, []);

  return [value, toggle];
};

export const useLocale = () => {
  return i18n.language.split('-').shift() ?? 'fr';
};

// TODO: à typer de la même manière que RoutePaths
export const useContextRedirection = () => {
  const navigate = useNavigate();
  const locale = useLocale();
  const memoLocale = useMemo(() => locale, [locale]);

  return useCallback(
    (to: string, params = {}) => {
      const path = axios.getUri({ url: `/${memoLocale}${to}`, params });
      navigate(path);
    },
    [navigate, memoLocale],
  );
};

export const useIsMobileView = (key: number | Breakpoint = 'md') => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(key));
};

/**
 * Get role of current user.
 *
 * @example
 * import { useRole } from 'hooks';
 *
 * const MyComponent = () => {
 *   const role = useRole();
 *
 *   return (
 *      <>
 *          My role is: {role}
 *      </>
 *   );
 * };
 */
export const useRole = () => {
  const currentUser = useSelector((state: GlobalState) => state.session.authenticateUser);

  return currentUser?.role;
};

export const useIsDoctor = () => {
  const role = useRole();
  return role === 'doctor' || role === 'clerk';
};

export const useIsClerk = () => {
  const role = useRole();
  return role === 'clerk';
};

export const useIsLaboratory = () => {
  const role = useRole();
  return role === 'laboratory';
};

export const useIsPatient = () => {
  const role = useRole();
  return role === 'patient';
};

export const useIsTempPatient = () => {
  const role = useRole();
  return role === 'temp_patient';
};

export const useIsAdmin = () => {
  const role = useRole();
  return role === 'bionext_admin';
};

export const useCurrentUser = () => {
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);

  return currentUser;
};

export const useAuthenticatedUser = () => {
  const authenticateUser = useSelector((state: GlobalState) => state.session.authenticateUser);

  return authenticateUser;
};

export const useIsRelative = () => {
  const currentUser = useCurrentUser();
  return currentUser?.is_relative;
};

export const useQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useRelatives = () => {
  const currentUser = useSelector((state: GlobalState) => state.session.authenticateUser);

  return currentUser?.relatives;
};

export const useCurrentUserRelatives = () => {
  return useSelector((state: GlobalState) => state.session.relativesCurrentUser);
};

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const notification = useCallback(
    (message: string, variant: 'default' | 'error' | 'success' | 'warning' | 'info') => {
      return enqueueSnackbar(`${variant === 'error' ? t('notifications.error.prefixMessage') : ''} ${message}`, {
        variant: variant,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 2000,
      });
    },
    [enqueueSnackbar, t],
  );

  return { notification };
};

export const useWhatsNew = () => {
  const flag = localStorage.getItem(CONST_WHATSNEW_FLAG);
  return flag;
};
