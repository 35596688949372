import { Box, Button, Typography } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { Props } from '../index';
import CustomButton from '../../../../components/buttons/custom-button';
import { useTranslation } from 'react-i18next';
import { useContextRedirection } from '../../../../hooks';
import RoutePaths from '../../../../utils/RoutePaths';
import BookingNavigation from './Booking.navigation';

const BookingAtCenter: FC<Props> = (props) => {
  const { t } = useTranslation();

  const submitHandler = useCallback(
    (argAtCenter: boolean) => {
      props.setAtCenter(argAtCenter);
      props.setIntroductionStep(props.selectablePersons.length > 0 ? 'choose-patients' : 'choose-patient-number');
    },
    [props],
  );

  const navigate = useContextRedirection();

  const gotoAppointment = () => {
    navigate(RoutePaths['BOOK_APPOINTMENT']);
  };

  // Shortcuts
  let atCenter = props.atCenter;
  let selectablePersons = props.selectablePersons;
  let setIntroductionStep = props.setIntroductionStep;

  return (
    <>
      <Box maxWidth='600px' margin='auto' flex={1} display='flex' flexDirection='column' justifyContent='center'>
        <Box display='flex' flexDirection='column' justifyContent='center' mb={2}>
          <Box mb={2}>
            <CustomButton fullWidth={true} inverted={atCenter === true} onClick={() => submitHandler(true)}>
              {t('preregistration.locationChoice.atCenter')}
            </CustomButton>
          </Box>
          <CustomButton fullWidth={true} inverted={atCenter === false} onClick={() => submitHandler(false)}>
            {t('preregistration.locationChoice.notAtCenter')}
          </CustomButton>
        </Box>
        <Typography textAlign={'center'}>{t('preregistration.locationChoice.or')}</Typography>
        <Box mt={2}>
          <Button fullWidth={true} variant='outlined' onClick={() => gotoAppointment()}>
            {t('preregistration.locationChoice.bookAppointment')}
          </Button>
        </Box>
      </Box>
      <BookingNavigation
        handleNext={() => {
          setIntroductionStep(selectablePersons.length > 0 ? 'choose-patients' : 'choose-patient-number');
        }}
        showBack={false}
        disabledNext={atCenter === null}
        showNext={atCenter !== null}
      />
    </>
  );
};

export default BookingAtCenter;
