import { Close, Home, Info, Work } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import ToggleButton from 'components/buttons/toggle-button';
import { useIsMobileView } from 'hooks';
import { Address } from 'models/profile.model';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddressType } from '..';

type PatientKnownAddressProps = {
  name: string;
  icon?: React.ReactNode;
  address: Address;
  selected: boolean;
  showAddresses: boolean;
  onSelected: () => void;
};

const PatientKnownAddress: FC<PatientKnownAddressProps> = ({ name, icon, address, selected, showAddresses, onSelected }) => {
  const isMobileView = useIsMobileView();

  return (
    <Box sx={{ lineHeight: 1 }} display='flex' flexDirection={isMobileView ? 'column' : 'row'} alignItems={isMobileView ? 'initial' : 'center'} gap={4}>
      <ToggleButton selected={selected} onToggle={onSelected}>
        {icon && <Box sx={{ mr: 1, lineHeight: 1 }}>{icon}</Box>}
        {name}
      </ToggleButton>
      {!isMobileView && (
        <Box
          sx={{
            opacity: showAddresses ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out',
            pointerEvents: showAddresses ? 'all' : 'none',
          }}
        >
          <Typography variant='body2'>
            {address.number} {address.street}, {address.city}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

type PatientKnwonAddressProps = {
  patientHomeAddress: Address | undefined;
  patientWorkAddress: Address | undefined;
  selectedAddressType: AddressType | null;
  selectPatientKnownAddress: ({ type, address }: { type: AddressType; address: Address }) => void;
};

const PatientKnownAddresses: FC<PatientKnwonAddressProps> = ({ patientHomeAddress, patientWorkAddress, selectedAddressType, selectPatientKnownAddress }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  // @see #50 Validate addresse usage
  if( !patientHomeAddress?.number && !patientHomeAddress?.street && !patientHomeAddress?.city ) {
    patientHomeAddress = undefined;
  }
  if( !patientWorkAddress?.number && !patientWorkAddress?.street && !patientWorkAddress?.city ) {
    patientWorkAddress = undefined;
  }

  return (
    <Box display='flex' justifyContent='space-between' width='100%' mt={4}>
      <Box display='flex' flexDirection={isMobileView ? 'row' : 'column'} gap={2}>
        {patientHomeAddress && (
          <PatientKnownAddress
            name={t('appointment.book.steps.search.address.home')}
            icon={<Home />}
            address={patientHomeAddress}
            selected={selectedAddressType === 'home'}
            showAddresses={true}
            onSelected={() => selectPatientKnownAddress({ type: 'home', address: patientHomeAddress! })}
          />
        )}
        {patientWorkAddress && (
          <PatientKnownAddress
            name={t('appointment.book.steps.search.address.work')}
            icon={<Work />}
            address={patientWorkAddress}
            selected={selectedAddressType === 'work'}
            showAddresses={true}
            onSelected={() => selectPatientKnownAddress({ type: 'work', address: patientWorkAddress! })}
          />
        )}
      </Box>
    </Box>
  );
};

export default PatientKnownAddresses;
