import { Box, Typography } from '@mui/material';
import CustomTextField from 'components/custom-textfield';
import config from 'config';
import { useIsMobileView, useLocale } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Marker, StaticGoogleMap } from 'react-static-google-map';
import BookingSearchDatePicker from './Booking.search.date.picker';
import BookingSearchSlotPicker from './Booking.search.slot.picker';
import { Rdv } from 'models/appointment.model';
import { MemoizedSlots } from '..';
import { useEffect, useState } from 'react';
import { formatDate, getFirstDayOfMonth, getLastDayOfMonth, getSlotsKey, isCenterOnly } from 'utils/func/Appointment.func';
import API from 'api';

type BookingSearchSelectedAddressProps = {
  rdv: Rdv;
  selectedDate: Date | null;
  memoizedSlots: MemoizedSlots;
  setRdv: React.Dispatch<React.SetStateAction<Rdv>>;
  setSelectedDate: React.Dispatch<React.SetStateAction<Date | null>>;
  selectSlot: (slot: string) => void;
  setMemoizedSlots: React.Dispatch<React.SetStateAction<MemoizedSlots>>;
};

const BookingSearchSelectedAddress: React.FC<BookingSearchSelectedAddressProps> = ({ rdv, selectedDate, memoizedSlots, setRdv, setSelectedDate, selectSlot, setMemoizedSlots }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();
  const locale = useLocale();

  const [startDate, setStartDate] = useState<Date>(getFirstDayOfMonth(selectedDate ?? new Date()));
  const [endDate, setEndDate] = useState<Date>(() => {
    const date = selectedDate ?? new Date();
    // @see #27 - if the selected date is near the last day of the month
    // we get the slots from the next month too
    if (date.getDate() > 15) {
      date.setDate(date.getDate() + 20);
    }
    return getLastDayOfMonth(date);
  });
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [slotsOpen, setSlotsOpen] = useState(!!rdv.slot);

  /**
   * Fetching slots (at home)
   */
  useEffect(() => {
    // Don't fetch if no address is selected or if a center is selected
    if (!rdv.coordInfo || isCenterOnly(rdv) || !!rdv.center) {
      return;
    }

    // Don't fetch if slots are already memoized for the given dates
    const slotsKey = getSlotsKey(startDate, endDate, rdv);
    if (memoizedSlots.has(slotsKey)) {
      return;
    }

    const fetchSlots = async () => {
      try {
        setLoadingSlots(true);

        const slots = await API.appointments.getSlots(startDate, endDate, rdv.prescriptions, rdv.coordInfo!.lat, rdv.coordInfo!.lon);

        setMemoizedSlots((currentMemoizedSlots) => {
          const newMemoizedSlots = new Map(currentMemoizedSlots);
          newMemoizedSlots.set(slotsKey, slots);
          return newMemoizedSlots;
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingSlots(false);
      }
    };

    fetchSlots();
  }, [startDate, endDate, rdv, rdv.coordInfo, rdv.prescriptions, rdv.center, memoizedSlots, setRdv, setMemoizedSlots]);

  /**
   * Fetching slots (in center)
   */
  useEffect(() => {
    // Don't fetch if no center is selected
    if (!rdv.center) {
      return;
    }

    // Don't fetch if slots are already memoized for the given dates
    const slotsKey = getSlotsKey(startDate, endDate, rdv);
    if (memoizedSlots.has(slotsKey)) {
      return;
    }

    const fetchSlots = async () => {
      try {
        setLoadingSlots(true);

        const slots = await API.appointments.getCenterSlots(rdv.center!.id, startDate, endDate, rdv.prescriptions.map((prescription) => prescription.analyses).flat());

        setMemoizedSlots((currentMemoizedSlots) => {
          const newMemoizedSlots = new Map(currentMemoizedSlots);
          newMemoizedSlots.set(slotsKey, slots);
          return newMemoizedSlots;
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingSlots(false);
      }
    };

    fetchSlots();
  }, [startDate, endDate, rdv, rdv.center, rdv.prescriptions, memoizedSlots, setRdv, setMemoizedSlots]);

  /**
   * Select first date with available slots
   */
  useEffect(() => {
    const availableSlots = memoizedSlots.get(getSlotsKey(startDate, endDate, rdv));
    // Do NOT change selected date if a slot is already selected
    if (availableSlots && !rdv.slot) {
      for (const date in availableSlots) {
        if (availableSlots[date].filter((slot) => slot.nbrSlot > 0).length > 0) {
          setSelectedDate(new Date(date));
          return;
        }
      }
    }
  }, [startDate, endDate, rdv, memoizedSlots, setSelectedDate]);

  /**
   * Show slots when a slot is (programmatically) selected
   */
  useEffect(() => {
    if (rdv.slot) {
      setSlotsOpen(true);
    }
  }, [rdv.slot]);

  const availableSlotsForSelectedDate = !selectedDate ? [] : memoizedSlots.get(getSlotsKey(startDate, endDate, rdv))?.[formatDate(selectedDate)] ?? [];

  return (
    <Box
      display='flex'
      flexDirection={isMobileView ? 'column' : 'row'}
      mt={4}
      width={isMobileView ? '100%' : '150%'}
      sx={
        isMobileView
          ? {}
          : {
              transition: 'transform 0.5s ease-in-out',
              transform: `translateX(calc(${slotsOpen ? -1 : 1} * 100% / 6))`,
            }
      }
      gap={2}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='center'
        gap={2}
        flex='1 1 50%'
        py={2}
        sx={
          isMobileView
            ? {}
            : {
                boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A',
                borderRadius: '10px',
                opacity: slotsOpen ? 0 : 1,
                transition: 'opacity 0.5s ease-in-out',
                pointerEvents: slotsOpen ? 'none' : 'all',
              }
        }
      >
        <Box display='flex' flexDirection={isMobileView ? 'row' : 'column'} alignItems='center' gap={2}>
          <Box px={isMobileView ? 0 : 2}>
            {rdv.center && (
              <Box>
                <Box padding={2}>
                  <Typography textAlign='center' fontWeight='500'>
                    {t('appointment.book.steps.search.carousel.center_title')}
                  </Typography>
                </Box>
              </Box>
            )}
            {(!rdv.center || rdv.center.imageUrl) && (
              <Box display='flex' justifyContent='center' alignItems='center' border='2px solid #212121' borderRadius='4px' width='100%' height='auto'>
                {!rdv.center && rdv.coordInfo ? (
                  <StaticGoogleMap
                    size='400x300'
                    zoom='14'
                    apiKey={config.googleMapsApiKey}
                    width='100%'
                    height='auto'
                    language={locale}
                    region={rdv.coordInfo.country_code ? rdv.coordInfo.country_code.toUpperCase() : ''}
                  >
                    <Marker location={rdv.coordInfo.lat + ',' + rdv.coordInfo.lon} color={0x46bff5} />
                  </StaticGoogleMap>
                ) : (
                  rdv.center?.imageUrl && <img width='250px' src={`${config.myRdvBaseUrl}${rdv.center?.imageUrl}`} alt={rdv.center?.name ?? 'Center'} />
                )}
              </Box>
            )}
          </Box>
          <Box>
            <Box px={2}>
              {rdv.center?.name && (
                <Typography textAlign='center' fontWeight='700'>
                  {rdv.center.name}
                </Typography>
              )}
              <Typography textAlign='center' fontWeight='500'>
                {rdv.center?.address ?? rdv.coordInfo?.display_name}
              </Typography>
            </Box>
          </Box>
        </Box>
        {!rdv.center && rdv.coordInfo && (
          <Box px={isMobileView ? 0 : 2}>
            <CustomTextField
              label={t('appointment.book.steps.search.comment_field.label')}
              placeholder={t('appointment.book.steps.search.comment_field.placeholder')}
              multiline
              rows={3}
              value={rdv.coordInfo.comment}
              onChange={(event) => {
                setRdv((currentRdv) => ({ ...currentRdv, coordInfo: { ...currentRdv.coordInfo!, comment: event.target.value } }));
              }}
              fullWidth
            />
          </Box>
        )}
      </Box>
      <BookingSearchDatePicker
        rdv={rdv}
        startDate={startDate}
        endDate={endDate}
        selectedDate={selectedDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setSelectedDate={setSelectedDate}
        loadingSlots={loadingSlots}
        slotsOpen={slotsOpen}
        setSlotsOpen={setSlotsOpen}
        memoizedSlots={memoizedSlots}
        setMemoizedSlots={setMemoizedSlots}
      />
      <BookingSearchSlotPicker
        rdv={rdv}
        selectedDate={selectedDate}
        startDate={startDate}
        endDate={endDate}
        availableSlotsForSelectedDate={availableSlotsForSelectedDate}
        slotsOpen={slotsOpen}
        selectSlot={selectSlot}
      />
    </Box>
  );
};

export default BookingSearchSelectedAddress;
