import { Add } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import CustomButton from 'components/buttons/custom-button';
import ToggleButton from 'components/buttons/toggle-button';
import { RdvPatient, RdvPrescription } from 'models/appointment.model';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createAnonymousPatientsFromNumber, createPatientFromSelectablePerson } from 'utils/func/Appointment.func';
import { getAppointmentTempPatientName } from 'utils/func/Person.func';
import { Props } from '../index';
import BookingNavigation from './Booking.navigation';

type PatientSelectorProps = {
  index: number;
  person: RdvPatient;
  selected?: boolean;
  onToggle: () => void;
};

const PatientSelector: FC<PatientSelectorProps> = ({ index, person, selected, onToggle }) => {
  const { t } = useTranslation();
  return (
    <Box mr={2}>
      <ToggleButton selected={selected} onToggle={onToggle} closable>
        {getAppointmentTempPatientName(person, index, t)}
      </ToggleButton>
    </Box>
  );
};

const BookingPatientsSelect: FC<Props> = ({ rdv, selectablePersons, setRdv, setIntroductionStep }) => {
  const { t } = useTranslation();
  const [selectedSelectablePersons, setSelectedSelectablePersons] = useState<RdvPrescription[]>(rdv.prescriptions.filter((prescription) => prescription.patient?.id));
  const [anonymousPatients, setAnonymousPatients] = useState<RdvPrescription[]>(rdv.prescriptions.filter((prescription) => !prescription.patient?.id));

  const patientsToDisplay = useMemo(() => [...selectablePersons.map((person) => createPatientFromSelectablePerson(person)), ...anonymousPatients], [selectablePersons, anonymousPatients]);

  const handleToggle = (prescription: RdvPrescription) => {
    if (selectablePersons.some((selectablePerson) => selectablePerson.patient_id === prescription.id)) {
      // Case for connected patient or relatives
      setSelectedSelectablePersons((currentSelectedSelectablePersons) => {
        const personIndex = currentSelectedSelectablePersons.findIndex((p) => p.patient?.id === prescription.patient?.id);

        if (personIndex !== -1) {
          // Remove
          return currentSelectedSelectablePersons.filter((p) => p.id !== prescription.id);
        } else {
          // Add
          return [...currentSelectedSelectablePersons, { ...prescription }];
        }
      });
    } else {
      // Case for anonymous patients
      setAnonymousPatients((currentAnonymousPatients) => currentAnonymousPatients.filter((p) => p.id !== prescription.id));
    }
  };

  const handleAdd = () => {
    setAnonymousPatients((currentAnonymousPatients) => [...currentAnonymousPatients, ...createAnonymousPatientsFromNumber(1)]);
  };

  const patientIsSelected = (prescriptionId: string) => {
    return rdv.prescriptions.findIndex((prescription) => prescription.id === prescriptionId) !== -1;
  };

  useEffect(() => {
    setRdv((currentRdv) => ({
      ...currentRdv,
      prescriptions: [...selectedSelectablePersons, ...anonymousPatients],
    }));
  }, [anonymousPatients, selectedSelectablePersons, setRdv]);

  return (
    <Box display='flex' flexDirection='column' flex={1}>
      <Box display='flex' flexDirection='column' flex={1} justifyContent='center' alignSelf='center' maxWidth='700px'>
        <Box mb={4} justifyContent='center'>
          <Typography variant='h2' textAlign='center'>
            {t('appointment.book.steps.introduction.persons.title')}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' gap={2} flexWrap='wrap'>
          {patientsToDisplay.map((prescription, index) => (
            <PatientSelector
              key={prescription.id}
              index={patientsToDisplay.slice(0, index).reduce((acc, p) => (!p.patient?.id || patientIsSelected(p.id) ? acc + 1 : acc), 0)}
              person={prescription.patient!}
              selected={patientIsSelected(prescription.id)}
              onToggle={() => handleToggle(prescription)}
            />
          ))}
          <Box mr={2}>
            <CustomButton onClick={handleAdd}>
              <Add />
              {t('appointment.book.steps.introduction.buttons.addPerson')}
            </CustomButton>
          </Box>
        </Box>
        <BookingNavigation showBack={false} handleNext={() => setIntroductionStep('choose-acts')} disabledNext={rdv.prescriptions.length < 1} />
      </Box>
    </Box>
  );
};

export default BookingPatientsSelect;
