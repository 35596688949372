import { FC, useCallback, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIsMobileView, useNotification } from 'hooks';
import { Props } from '..';
import { Rdv, RdvOrdonnance } from 'models/appointment.model';
import BookingNavigation from './Booking.navigation';
import BookingPrescriptionPatient from './Booking.prescriptions.patient';
import { convertDate } from 'utils/func/Date.func';
import api from 'api';
import CustomDialog from 'components/custom-dialog';
import QRCode from 'react-qr-code';
import ContextLink from 'components/_base/ContextLink';

const BookingPrescriptionStep: FC<Props> = ({ rdv, atCenter, setRdv, handleBack, handleNext, setActiveStep }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const isMobileView = useIsMobileView();

  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<any>(null);

  const handleAddOrdonnanceToPatient = (prescriptionId: string, ordonnance: RdvOrdonnance) => {
    setRdv((currentRdv) => {
      const prescription = currentRdv.prescriptions.find((p) => p.id === prescriptionId);
      if (!prescription) {
        notification(t('appointment.book.steps.reasons.medical_acts.notification.errorOnUploading'), 'error');
        return currentRdv;
      }

      const ordonnances = [...prescription.ordonnances, ordonnance];
      const prescriptions = currentRdv.prescriptions.map((p) => (p.id === prescriptionId ? { ...p, ordonnances } : p));
      return { ...currentRdv, prescriptions };
    });
  };

  const handleDeleteOrdonnanceFromPatient = (prescriptionId: string, ordonnanceIdx: number) => {
    setRdv((currentRdv) => {
      const prescription = currentRdv.prescriptions.find((p) => p.id === prescriptionId);
      if (!prescription) {
        notification(t('appointment.book.steps.reasons.medical_acts.notification.errorOnUploading'), 'error');
        return currentRdv;
      }

      const ordonnances = [...prescription.ordonnances];
      ordonnances.splice(ordonnanceIdx, 1);

      const prescriptions = currentRdv.prescriptions.map((p) => (p.id === prescriptionId ? { ...p, ordonnances } : p));
      return { ...currentRdv, prescriptions };
    });
  };

  const sendCorrectData = useCallback((atCenter: boolean | null | undefined, rdv?: Rdv) => {
    const arrayOfUser: any[] = [];
    if (rdv) {
      rdv.prescriptions &&
        rdv.prescriptions.forEach((prescription) => {
          const files: { name: string; data: string }[] = [];

          let user = prescription.patient;

          prescription.ordonnances &&
            prescription.ordonnances.forEach((el: RdvOrdonnance, key: number) => {
              files.push({ name: el.file_name, data: el.file_content });
            });

          arrayOfUser.push({
            mylab_id: user.id ?? null, // user.id is MYLAB ID when assigned
            first_name: user.firstname,
            // HOTFIX: birthname should be present, if not take lastname
            last_name: user.lastname || user.birthname,
            birth_name: user.birthname || user.lastname,
            birth_date: convertDate(user.birthdate, false, 'yyyy-MM-dd'),
            gender: user.gender,
            matricule: user.cns,
            mobile_phone: user.phone,
            email: user.email,
            files: files,
            is_at_center: !!atCenter,
          });
        });
    }
    return arrayOfUser;
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const userData = sendCorrectData(atCenter, rdv);
    if (userData) {
      api.preregistration
        .preregister(userData)
        .then((response) => {
          setResponse({ patientsWithQrcodes: response });
        })
        .catch((error) => {
          console.error(error);
          notification(t('preregistration.validation.notification.error'), 'error');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  console.log({ loading });

  return (
    <>
      <Box display='flex' flexDirection='column' justifyContent='center' flex={1}>
        <Box display='flex' flexDirection='column' flex={1}>
          <Box display='flex' flexDirection='column' justifyContent='center' alignSelf='center' width={isMobileView ? 'auto' : '700px'}>
            <Box my={4} justifyContent='center'>
              <Typography variant='h2' textAlign='center'>
                {t('preregistration.prescriptions.title')}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='column' gap={2} flex={1}>
              <Box width={isMobileView ? 'auto' : '500px'} alignSelf='center'>
                <Typography variant='body1' fontSize='0.8rem'>
                  {t('preregistration.prescriptions.description')}
                </Typography>
              </Box>
              {rdv.prescriptions.map((prescription, index) => (
                <BookingPrescriptionPatient
                  key={prescription.id}
                  index={index}
                  prescription={prescription}
                  onAddOrdonnance={handleAddOrdonnanceToPatient}
                  onDeleteOrdonnance={handleDeleteOrdonnanceFromPatient}
                />
              ))}
            </Box>
            <BookingNavigation
              handleBack={handleBack}
              handleNext={handleSubmit}
              showNext={rdv.prescriptions.every((prescription) => prescription.ordonnances.length > 0)}
              nextElement={t('appointment.book.action_buttons.done')}
              disabledNext={loading}
            />
          </Box>
        </Box>
      </Box>
      <CustomDialog open={!loading && response}>
        <Box display='flex' flexDirection='column' alignItems='center' gap={2} p={4}>
          <Typography variant='h2' textAlign='center'>
            {t(`preregistration.summary.${response?.error ? 'error' : 'success'}.title`)}
          </Typography>
          <Typography variant='body1'>{t(`preregistration.summary.${response?.error ? 'error' : 'success'}.description`)}</Typography>
          <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} flexWrap='wrap' gap={2}>
            {response?.patientsWithQrcodes &&
              response.patientsWithQrcodes.map((patient: any) => (
                <Box key={patient.patientId} display='flex' flexDirection='column' alignItems='center' gap={2}>
                  <Typography fontWeight='500'>{`${patient.first_name} ${patient.last_name || patient.birth_name}`.trim()}</Typography>
                  <QRCode value={patient.qrcode} size={128} />
                </Box>
              ))}
          </Box>
          <ContextLink to='HOME'>
            <Button variant='contained'>{t('appointment.book.action_buttons.back_home')}</Button>
          </ContextLink>
        </Box>
      </CustomDialog>
    </>
  );
};

export default BookingPrescriptionStep;
