import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useIsMobileView, useNotification } from 'hooks';
import { Props } from '..';
import { RdvOrdonnance } from 'models/appointment.model';
import BookingNavigation from './Booking.navigation';
import BookingPrescriptionPatient from './Booking.prescriptions.patient';

const BookingPrescriptionStep: FC<Props> = ({ rdv, setRdv, handleBack, handleNext }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const isMobileView = useIsMobileView();

  const handleAddOrdonnanceToPatient = (prescriptionId: string, ordonnance: RdvOrdonnance) => {
    setRdv((currentRdv) => {
      const prescription = currentRdv.prescriptions.find((p) => p.id === prescriptionId);
      if (!prescription) {
        notification(t('appointment.book.steps.reasons.medical_acts.notification.errorOnUploading'), 'error');
        return currentRdv;
      }

      const ordonnances = [...prescription.ordonnances, ordonnance];
      const prescriptions = currentRdv.prescriptions.map((p) => (p.id === prescriptionId ? { ...p, ordonnances } : p));
      return { ...currentRdv, prescriptions };
    });
  };

  const handleDeleteOrdonnanceFromPatient = (prescriptionId: string, ordonnanceIdx: number) => {
    setRdv((currentRdv) => {
      const prescription = currentRdv.prescriptions.find((p) => p.id === prescriptionId);
      if (!prescription) {
        notification(t('appointment.book.steps.reasons.medical_acts.notification.errorOnUploading'), 'error');
        return currentRdv;
      }

      const ordonnances = [...prescription.ordonnances];
      ordonnances.splice(ordonnanceIdx, 1);

      const prescriptions = currentRdv.prescriptions.map((p) => (p.id === prescriptionId ? { ...p, ordonnances } : p));
      return { ...currentRdv, prescriptions };
    });
  };
  
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' flex={1}>
      <Box display='flex' flexDirection='column' flex={1}>
        <Box display='flex' flexDirection='column' justifyContent='center' alignSelf='center'  width={isMobileView ? 'auto' : '700px'}>
          <Box my={4} justifyContent='center'>
            <Typography variant='h2' textAlign='center'>
              {t('appointment.book.steps.prescriptions.title')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' gap={2} flex={1}>
            {rdv.prescriptions.map((prescription, index) => (
              <BookingPrescriptionPatient
                key={prescription.id}
                index={index}
                prescription={prescription}
                onAddOrdonnance={handleAddOrdonnanceToPatient}
                onDeleteOrdonnance={handleDeleteOrdonnanceFromPatient}
              />
            ))}
          </Box>
          <BookingNavigation
            handleBack={handleBack}
            handleNext={handleNext}
            nextElement={rdv.prescriptions.some((prescription) => prescription.ordonnances.length > 0) ? t('appointment.book.action_buttons.next') : t('appointment.book.action_buttons.skip')}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BookingPrescriptionStep;
