import { Add, Close } from '@mui/icons-material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import CustomDialog from 'components/custom-dialog';
import { useIsMobileView, useNotification } from 'hooks';
import { RdvOrdonnance, RdvPrescription } from 'models/appointment.model';
import { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { MAX_SIZE_FILE } from 'utils/Constants';
import { getAppointmentTempPatientName } from 'utils/func/Person.func';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type PrescriptionPreviewProps = {
  patientName: string;
  ordonnance: RdvOrdonnance;
  dialogStep: number;
  onReplace: () => void;
  onAdd: () => void;
  onNext: () => void;
};

const PrescriptionPreview: FC<PrescriptionPreviewProps> = ({ patientName, ordonnance, dialogStep, onReplace, onAdd, onNext }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  const [zoomed, setZoomed] = useState<boolean>(false);
  const [documentWidth, setDocumentWidth] = useState<number>(0);
  const [numberOfPages, setNumberOfPages] = useState(1);

  const onLoadSuccess: Document['props']['onLoadSuccess'] = ({ numPages }) => {
    setNumberOfPages(numPages);
  };

  const documentContainerRef = useRef<HTMLDivElement>(null);

  const maxWidthDesktopDocumentRatio = 0.5; // 50%
  useEffect(() => {
    if (documentContainerRef.current) {
      if (zoomed || isMobileView) {
        setDocumentWidth(documentContainerRef.current.offsetWidth - 2);
      } else if (documentContainerRef.current.parentElement) {
        setDocumentWidth(documentContainerRef.current.parentElement.offsetWidth * maxWidthDesktopDocumentRatio - 2);
      }
    }
  }, [setDocumentWidth, isMobileView, zoomed]);

  return (
    <Box display='flex' flexDirection='column' gap={2} p={isMobileView ? 2 : 4}>
      {!zoomed && (
        <>
          <Typography variant='h2'>{t('appointment.book.steps.prescriptions.check.patient', { replace: { name: patientName } })}</Typography>
          <Typography variant='h3'>{ordonnance.file_name}</Typography>
        </>
      )}
      <Box display='flex' flexDirection={isMobileView ? 'column-reverse' : 'row'} gap={4}>
        <Box
          position='relative'
          border={zoomed ? 'none' : '1px solid #808080'}
          sx={{ cursor: 'pointer' }}
          ref={documentContainerRef}
          maxWidth={zoomed || isMobileView ? 'none' : `${maxWidthDesktopDocumentRatio * 100}%`}
        >
          <Typography mt={1} fontSize='0.8rem' textAlign='center'>
            {t(`appointment.book.steps.prescriptions.check.${zoomed ? 'close' : 'open'}`)}
          </Typography>
          {ordonnance.file_type === 'application/pdf' && (
            <Document file={`data:application/pdf;base64,${ordonnance.file_content}`} onLoadSuccess={onLoadSuccess}>
              {Array.from({ length: zoomed ? numberOfPages : 1 }, (_, index) => (
                <Page
                  onClick={() => setZoomed((currentZoomState) => !currentZoomState)}
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  height={zoomed || isMobileView ? undefined : 500}
                  width={zoomed || !documentWidth ? undefined : documentWidth}
                />
              ))}
            </Document>
          )}
          {(ordonnance.file_type || '').startsWith('image/') && (
            <img src={`data:${ordonnance.file_type};base64,${ordonnance.file_content}`} alt={ordonnance.file_name} onClick={() => setZoomed((currentZoomState) => !currentZoomState)} width='100%' />
          )}
        </Box>
        <Box display={zoomed ? 'none' : 'flex'} flexDirection='column' justifyContent='center' gap={3}>
          {dialogStep === 1 ? (
            <>
              <Box display='flex' flexDirection='column' gap={3}>
                <Typography variant='h3' textAlign={isMobileView ? 'center' : 'left'}>
                  {t('appointment.book.steps.prescriptions.check.title')}
                </Typography>
                <Typography fontSize={'0.8rem'} textAlign={isMobileView ? 'center' : 'left'}>
                  {t('appointment.book.steps.prescriptions.check.description')}
                </Typography>
              </Box>
              <Box display='flex' justifyContent={isMobileView ? 'center' : 'flex-start'} gap={2}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    onNext();
                  }}
                >
                  {t('appointment.book.action_buttons.yes')}
                </Button>
                <Button variant='outlined' onClick={onReplace}>
                  {t('appointment.book.action_buttons.no')}
                </Button>
              </Box>
            </>
          ) : dialogStep === 2 ? (
            <>
              <Box display='flex' flexDirection='column' gap={3}>
                <Typography variant='h3' textAlign={isMobileView ? 'center' : 'left'}>
                  {t('appointment.book.steps.prescriptions.check.add_new_page')}
                </Typography>
              </Box>
              <Box display='flex' justifyContent={isMobileView ? 'center' : 'flex-start'} gap={2}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    onAdd();
                  }}
                >
                  {t('appointment.book.action_buttons.yes')}
                </Button>
                <Button variant='outlined' onClick={onNext}>
                  {t('appointment.book.action_buttons.no')}
                </Button>
              </Box>
            </>
          ) : (
            <>
              <Box display='flex' flexDirection='column' gap={3}>
                <Typography variant='h3' textAlign={isMobileView ? 'center' : 'left'}>
                  {t('appointment.book.steps.prescriptions.check.add_new_prescription', { replace: { name: patientName } })}
                </Typography>
              </Box>
              <Box display='flex' justifyContent={isMobileView ? 'center' : 'flex-start'} gap={2}>
                <Button
                  variant='outlined'
                  onClick={() => {
                    onAdd();
                  }}
                >
                  {t('appointment.book.action_buttons.yes')}
                </Button>
                <Button variant='outlined' onClick={onNext}>
                  {t('appointment.book.action_buttons.no')}
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

type BookingPrescriptionPatientProps = {
  index: number;
  prescription: RdvPrescription;
  onAddOrdonnance: (prescriptionId: string, ordonnance: RdvOrdonnance) => void;
  onDeleteOrdonnance: (prescriptionId: string, ordonnanceIdx: number) => void;
};

const BookingPrescriptionPatient: FC<BookingPrescriptionPatientProps> = ({ index, prescription, onAddOrdonnance, onDeleteOrdonnance }) => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const isMobileView = useIsMobileView();

  const [ordonnanceToCheck, setOrdonnanceToCheck] = useState<RdvOrdonnance | null>(null);
  const [ordonnanceToDelete, setOrdonnanceToDelete] = useState<RdvOrdonnance | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [dialogStep, setDialogStep] = useState(1);

  const inputFileRef = useRef<HTMLInputElement>(null);

  const handleAddPrescription = () => {
    inputFileRef.current?.click();
  };

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!e.target.files || e.target.files.length < 1) return;

    const file = e.target.files[0];
    if (!file || !(file.type === 'application/pdf' || file.type.startsWith('image/')) || file.size > MAX_SIZE_FILE) {
      notification(t('appointment.book.steps.prescriptions.notification.error.upload'), 'error');

      e.target.value = '';
      return;
    }

    if (prescription.ordonnances.findIndex((o) => o.file_name === file.name) > -1) {
      notification(t('appointment.book.steps.prescriptions.notification.error.exists', { replace: { prescription: file.name } }), 'error');

      e.target.value = '';
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const [file_header, file_content] = reader.result?.toString().split(';base64,') || [];
      const file_type = file_header?.split(':')[1];
      const newOrdonnance = { file_name: file.name, file_content, file_type };
      onAddOrdonnance(prescription.id, newOrdonnance);
      setOrdonnanceToCheck(newOrdonnance);
      setDialogStep(1);
    };

    e.target.value = '';
  };

  const handleReplace = (ordonnance: RdvOrdonnance) => {
    const idx = prescription.ordonnances.findIndex((o) => o.file_name === ordonnance.file_name);
    if (idx > -1) {
      prescription.ordonnances.splice(idx, 1);
      setOrdonnanceToCheck(null);
      handleAddPrescription();

      if (inputFileRef.current) {
        inputFileRef.current.value = '';
      }
    }
  };

  useEffect(() => {
    if (ordonnanceToDelete && confirmDelete) {
      const idx = prescription.ordonnances.findIndex((o) => o.file_name === ordonnanceToDelete.file_name);
      if (idx > -1) {
        onDeleteOrdonnance(prescription.id, idx);
        setOrdonnanceToDelete(null);
        setConfirmDelete(false);
      }
    }
  }, [ordonnanceToDelete, confirmDelete, prescription.ordonnances, prescription.id, onDeleteOrdonnance]);

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        gap={3}
        sx={{
          boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A',
        }}
        p={2}
      >
        <Box>
          <Typography variant='h3'>{getAppointmentTempPatientName(prescription.patient, index, t)}</Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap={2} flex={1} padding={1}>
          {prescription.ordonnances.map((ordonnance, idx) => (
            <Box key={ordonnance.file_name || idx} display='flex' justifyContent='space-between' p={2} sx={{ boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A', borderRadius: '10px' }}>
              <Button variant='link' onClick={() => setOrdonnanceToCheck(ordonnance)}>
                <Typography fontSize='0.8rem' fontWeight='500'>
                  {ordonnance.file_name}
                </Typography>
              </Button>
              <IconButton onClick={() => setOrdonnanceToDelete(ordonnance)}>
                <Close fontSize='small' />
              </IconButton>
            </Box>
          ))}
          <Box>
            <input style={{ display: 'none' }} type='file' ref={inputFileRef} onChange={handleFileChange} accept='image/*, application/pdf' />
            <Button variant='outlined' fullWidth sx={{ fontWeight: '500' }} onClick={handleAddPrescription}>
              <Add fontSize='large' />
              {t('appointment.book.steps.prescriptions.add')}
            </Button>
          </Box>
        </Box>
        {ordonnanceToCheck && (
          <CustomDialog open={!!ordonnanceToCheck.file_name} fullScreen={isMobileView}>
            <PrescriptionPreview
              patientName={getAppointmentTempPatientName(prescription.patient, index, t)}
              ordonnance={ordonnanceToCheck}
              dialogStep={dialogStep}
              onAdd={handleAddPrescription}
              onNext={() => {
                setDialogStep((currentStep) => (currentStep < 3 ? currentStep + 1 : 1));
                if (dialogStep === 3) {
                  setOrdonnanceToCheck(null);
                }
              }}
              onReplace={() => handleReplace(ordonnanceToCheck)}
            />
          </CustomDialog>
        )}
        {ordonnanceToDelete && (
          <CustomDialog open={!!ordonnanceToDelete && !confirmDelete}>
            <Box display='flex' flexDirection='column' gap={2} p={4}>
              <Typography variant='h3'>
                {t('appointment.book.steps.prescriptions.delete')} {ordonnanceToDelete.file_name} ?
              </Typography>
              <Box display='flex' gap={2}>
                <Button variant='contained' onClick={() => setConfirmDelete(true)}>
                  {t('appointment.book.action_buttons.delete')}
                </Button>
                <Button variant='outlined' onClick={() => setOrdonnanceToDelete(null)}>
                  {t('appointment.book.action_buttons.cancel')}
                </Button>
              </Box>
            </Box>
          </CustomDialog>
        )}
      </Box>
    </>
  );
};

export default BookingPrescriptionPatient;
