import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import { useTranslation } from 'react-i18next';
import { Alert, Card, CircularProgress, Container, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import api from 'api';
import { useNotification } from 'hooks';
import { ValidateEmailTokenRequest } from '../../../models/user.model';
import { useLocation } from 'react-router-dom';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  const { t } = useTranslation();
  const { notification } = useNotification();
  const query = useQuery();
  const [validating, useValidating] = useState<boolean>(true);
  const [validated, useValidated] = useState<boolean>(false);

  useEffect(() => {
    const token = query.get('token');
    const data: ValidateEmailTokenRequest = {
      token: token
    };

    api.users
      .validateEmailToken(data)
      .then((response) => {
        useValidating(false);
        useValidated(true);
      })
      .catch(() => {
        useValidating(false);
      });
  }, [notification, t]);

  return (
    <BodyTemplate title={t('emailValidation.title')} backButton={false}>
      <Container maxWidth={'lg'} disableGutters sx={{ pt: 2 }}>
        <Grid>
          <Grid container justifyContent='center'>
            <Grid item xs={12}>
              <Card>
                {validating &&
                  <Typography textAlign='center'>
                    <CircularProgress />
                  </Typography>
                }
                {!validating && !validated &&
                  <Alert severity="error">
                    {t('emailValidation.error')}
                  </Alert>
                }
                {!validating && validated &&
                  <Alert severity="success">
                    {t('emailValidation.success')}
                  </Alert>
                }
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </BodyTemplate>
  );
};
