import { CircularProgress } from '@mui/material';
import { DateCalendar, DateCalendarProps } from '@mui/x-date-pickers';
import { FC } from 'react';
import CustomCalendarHeader from './custom-calendar-header';

const CustomDateCalendar: FC<DateCalendarProps<Date>> = (props) => {
  return (
    <DateCalendar
      {...props}
      sx={{
        '& .MuiDayCalendar-weekDayLabel': {
          fontWeight: '500',
          color: '#212121',
        },
        '& .MuiPickersDay-today': {
          borderWidth: '2px !important',
        },
        '& .Mui-selected': {
          backgroundColor: '#46BFF5 !important',
        },
        ...props.sx,
      }}
      slots={{
        ...props.slots,
        calendarHeader: CustomCalendarHeader,
      }}
      slotProps={{
        previousIconButton: {
          size: 'medium',
        },
        nextIconButton: {
          size: 'medium',
        },
        leftArrowIcon: {
          fontSize: 'medium',
        },
        rightArrowIcon: {
          fontSize: 'medium',
        },
        ...props.slotProps,
      }}
      dayOfWeekFormatter={(day) => `${day[0].toUpperCase()}${day[1].toLowerCase()}`}
      renderLoading={() => <CircularProgress />}
    />
  );
};

export default CustomDateCalendar;
