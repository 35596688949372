import { Box, Button, Card, CardActions, CardContent, List, Typography } from '@mui/material';
import api from 'api';
import ContextLink from 'components/_base/ContextLink';
import { isAfter, isEqual } from 'date-fns';
import { useNotification } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GlobalState } from 'store/reducers';
import { convertDate } from 'utils/func/Date.func';
import HomepageListingItems from './HomepageListingItems';

const HomepageAppointments: FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<{ date_time_from: string }[]>();
  const currentUser = useSelector((state: GlobalState) => state.session.currentUser);
  const { notification } = useNotification();

  useEffect(() => {
    currentUser &&
      api.appointments
        .getAppointments(currentUser.id)
        .then((appointments) =>
          setData(appointments.filter((appointment: any) => isAfter(new Date(appointment.date_time_from), new Date()) || isEqual(new Date(appointment.date_time_from), new Date())).slice(0, 3)),
        )
        .catch(() => notification(t('homepage.appointment.notification.error'), 'error'));
  }, [currentUser, notification, t]);

  return (
    <Card
      sx={{
        display: 'grid',
        width: '100%',
        minHeight: (theme) => theme.spacing(25),
      }}
    >
      <CardContent>
        <Typography variant='h2'>{t('homepage.appointment.title',{count: data?data.length:0})}</Typography>
        <ContextLink to={'APPOINTMENT'}>
          <List dense>
            {data && data.length > 0 ? (
              data.map((appointment, key: number) => {
                return (
                  <HomepageListingItems
                    title={
                      <Box component={Button} variant='header_light'>
                        <Typography>
                          {t('homepage.appointment.label', {
                            replace: {
                              date: convertDate(appointment.date_time_from, false, 'PPPp'),
                            },
                            interpolation: { escapeValue: false },
                          })}
                        </Typography>
                      </Box>
                    }
                    key={key}
                  />
                );
              })
            ) : (
              <Typography>{t('homepage.appointment.noAppointment')}</Typography>
            )}
          </List>
        </ContextLink>
      </CardContent>
      <CardActions sx={{ alignItems: 'flex-end' }}>
        <Box width='100%' display='flex' justifyContent='flex-end'>
          <ContextLink to='BOOK_APPOINTMENT'>
            <Button variant='contained'>{t('homepage.appointment.buttonLabel')}</Button>
          </ContextLink>
        </Box>
      </CardActions>
    </Card>
  );
};

export default HomepageAppointments;
