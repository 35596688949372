import { Box, Typography } from '@mui/material';
import CustomButton from 'components/buttons/custom-button';
import CustomDialog from 'components/custom-dialog';
import { RdvPrescription } from 'models/appointment.model';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRequiredFields } from 'utils/func/Appointment.func';
import { Props } from '..';
import BookingNavigation from './Booking.navigation';
import BookingPatientInfoCard from './Booking.patient.info.card';
import { cnsValidator } from 'utils/func/CnsValidator.func';
import { isGSMNumber } from 'utils/func/Phone.func';

export type PatientsInfoError = {
  prescriptionId: string;
  types: ('required' | 'email' | 'cns' | 'mobilephone')[];
  errors: {
    field: string;
    message?: string;
  }[];
};

const BookingPatientsInfoStep: FC<Props> = ({ rdv, setRdv, handleBack, handleNext, setActiveStep }) => {
  const { t } = useTranslation();

  const [prescriptionToDelete, setPrescriptionToDelete] = useState<RdvPrescription | null>(null);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [errors, setErrors] = useState<PatientsInfoError[]>([]);
  // @see#22 Use these state to activate/deactivate the next button
  const [displayNext, setDisplayNext] = useState<boolean>(false);

  const handleModifiedPrescription = (prescription: RdvPrescription) => {
    const index = rdv.prescriptions.findIndex((p) => p.id === prescription.id);

    if (index < 0) {
      return;
    }

    setRdv((currentRdv) => {
      const prescriptions = [...currentRdv.prescriptions];
      prescriptions[index].patient = prescription.patient;

      return { ...currentRdv, prescriptions };
    });
  };

  const handleRemovePrescritpion = useCallback(
    (prescription: RdvPrescription) => {
      const index = rdv.prescriptions.findIndex((p) => p.id === prescription.id);

      if (index < 0) {
        return;
      }

      setRdv((currentRdv) => {
        const prescriptions = [...currentRdv.prescriptions];
        prescriptions.splice(index, 1);

        return { ...currentRdv, prescriptions, slot: null };
      });

      setActiveStep(1);
    },
    [rdv.prescriptions, setRdv, setActiveStep],
  );

  /**
   * @see#22 Function used to collect all form errors
   */
  const collectPatientInfoErrors = () => {
    const patientsInfoErrors = rdv.prescriptions.reduce((acc1, prescription) => {
      const types = [] as PatientsInfoError['types'];

      // Required fields
      const errors = getRequiredFields(!!prescription.patient.id).reduce(
        (acc2, field) => {
          if (!prescription.patient?.[field]) {
            acc2.push({ field });
            if (!types.includes('required')) types.push('required');
          }

          return acc2;
        },
        [] as PatientsInfoError['errors'],
      );

      // Verify email
      if (prescription.patient?.email && prescription.patient?.verifyemail && prescription.patient?.email !== prescription.patient?.verifyemail) {
        errors.push({ field: 'email' }, { field: 'verifyemail' });
        types.push('email');
      }

      // Verify cns
      if (prescription.patient?.cns && prescription.patient?.birthdate && !cnsValidator(prescription.patient?.cns, prescription.patient?.birthdate.toString())) {
        errors.push({ field: 'birthdate' }, { field: 'cns' });
        types.push('cns');
      }

      // Verify mobile phone
      if (prescription.patient?.phone && !isGSMNumber(prescription.patient?.phone)) {
        errors.push({ field: 'phone' });
        types.push('mobilephone');
      }

      if (errors.length > 0) {
        acc1.push({ prescriptionId: prescription.id, errors, types });
      }
      return acc1;
    }, [] as PatientsInfoError[]);

    return patientsInfoErrors;
  };

  /**
   * @see#22 Function used as handleNext()
   */
  const checkPatientsInfo = () => {
    let errors = collectPatientInfoErrors();
    setErrors(errors);

    if (errors.length > 0) {
      window.scrollTo(0, 0);
      return;
    }

    handleNext();
  };

  /**
   * @see#22 Function used to activate/deactivate the button
   */
  const checkHandleNext = () => {
    let errors = collectPatientInfoErrors();
    if (displayNext && errors.length > 0) {
      setDisplayNext(false);
    } else if (!displayNext && errors.length === 0) {
      setDisplayNext(true);
    }
  };

  useEffect(() => {
    if (prescriptionToDelete && confirmDelete) {
      handleRemovePrescritpion(prescriptionToDelete);
      setPrescriptionToDelete(null);
      setConfirmDelete(false);
    }
  }, [prescriptionToDelete, confirmDelete, handleRemovePrescritpion]);

  return (
    <>
      <Box display='flex' flexDirection='column' justifyContent='center' flex={1}>
        <Box display='flex' flexDirection='column' flex={1}>
          <Box display='flex' flexDirection='column' flex={1} justifyContent='center'>
            <Box my={4} justifyContent='center'>
              <Typography variant='h2' textAlign='center'>
                {t('appointment.book.steps.patients_info.title', {count: rdv.prescriptions.length})}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='column' flex={1} gap={2} alignSelf='center'>
              {errors.length > 0 && errors.some((error) => error.types.includes('required')) && (
                <Box>
                  <Typography color='red' textAlign='center'>
                    {t('appointment.book.steps.patients_info.error_message.required')}
                  </Typography>
                </Box>
              )}
              {rdv.prescriptions.map((prescription, index) => (
                <BookingPatientInfoCard
                  key={prescription.id}
                  index={index}
                  prescription={prescription}
                  errors={errors.find((error) => error.prescriptionId === prescription.id)}
                  setPrescription={handleModifiedPrescription}
                  onRemovePrescription={() => setPrescriptionToDelete(prescription)}
                  enableRemove={rdv.prescriptions.length > 1}
                  onValid={checkHandleNext}
                />
              ))}
              <BookingNavigation handleBack={handleBack} handleNext={checkPatientsInfo} disabledNext={!displayNext} />
            </Box>
          </Box>
        </Box>
      </Box>
      <CustomDialog open={!!prescriptionToDelete && !confirmDelete}>
        <Box display='flex' flexDirection='column' gap={2} p={4}>
          <Typography variant='h3'>
            {t('appointment.book.steps.patients_info.delete_patient.title', {
              replace: {
                name: prescriptionToDelete
                  ? `${prescriptionToDelete.patient?.firstname ?? ''} ${(prescriptionToDelete.patient?.lastname ?? '') || prescriptionToDelete.patient?.birthname || ''}`.trim() || ''
                  : '',
              },
            })}
          </Typography>
          <Typography fontSize='0.8rem'>{t('appointment.book.steps.patients_info.delete_patient.description')}</Typography>
          <Box display='flex' gap={2}>
            <CustomButton inverted onClick={() => setConfirmDelete(true)}>
              {t('appointment.book.action_buttons.delete')}
            </CustomButton>
            <CustomButton onClick={() => setPrescriptionToDelete(null)}>{t('appointment.book.action_buttons.cancel')}</CustomButton>
          </Box>
        </Box>
      </CustomDialog>
    </>
  );
};

export default BookingPatientsInfoStep;
