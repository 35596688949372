import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import ToggleButton from 'components/buttons/toggle-button';
import { useIsMobileView } from 'hooks';
import { RdvAnalyse, RdvPrescription } from 'models/appointment.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getAppointmentTempPatientName } from 'utils/func/Person.func';

type ActProps = {
  id: number;
  name: string;
  selected: boolean;
  onToggleAct: (analyseId: number) => void;
};

const Act: FC<ActProps> = ({ id, name, selected, onToggleAct }) => {
  const isMobileView = useIsMobileView(1270);

  return (
    <ToggleButton fullWidth={isMobileView} selected={selected} onToggle={() => onToggleAct(id)}>
      {name}
    </ToggleButton>
  );
};

type ActsContainerProps = {
  acts: RdvAnalyse[];
  patientActs: RdvPrescription;
  labOrHome: 'labAndHome' | 'labOnly';
  onToggleAct: (prescriptionId: string, analyseId: number) => void;
};

const ActsContainer: FC<ActsContainerProps> = ({ acts, patientActs, labOrHome, onToggleAct }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView(1270);

  return (
    <Box flex='1 1 50%'>
      <Box padding={isMobileView ? 1 : 2} height='100%'>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems={isMobileView ? 'initial' : 'center'}
          gap={2}
          bgcolor='rgb(236, 249, 254)'
          borderRadius='10px'
          height='100%'
          flex={1}
          padding={2}
        >
          <Typography display={isMobileView ? 'inline' : 'none'} fontWeight='500' fontSize='0.7rem !important'>
            {t(`appointment.book.steps.introduction.acts.${labOrHome}`)}
          </Typography>
          <Box display='flex' gap={2} flexWrap='wrap' justifyContent='center'>
            {acts.map(({ id, name }) => (
              <Act key={id} name={name} id={id} selected={patientActs.analyses.some((analyse) => analyse.id === id)} onToggleAct={(analyseId: number) => onToggleAct(patientActs.id, analyseId)} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

type BookingActProps = {
  patientActs: RdvPrescription;
  index: number;
  atHomeActs: RdvAnalyse[];
  labOnlyActs: RdvAnalyse[];
  enableRemove: boolean;
  onToggleAct: (prescriptionId: string, analyseId: number) => void;
  onRemove: (patientId: string) => void;
};

const BookingPatientActs: FC<BookingActProps> = ({ patientActs, index, atHomeActs, labOnlyActs, enableRemove, onToggleAct, onRemove }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView(1270);

  return (
    <Box display='flex' py={2}>
      <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} sx={{ boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A', borderRadius: '10px' }} flex={1}>
        <Box width={isMobileView ? 'auto' : '150px'} padding={2} display='flex' alignItems='center' justifyContent='space-between'>
          <Typography fontWeight='500'>{getAppointmentTempPatientName(patientActs.patient, index, t)}</Typography>
          <Box display={isMobileView ? 'block' : 'none'}>
            <IconButton
              onClick={() => {
                if (patientActs.id) {
                  onRemove(patientActs.id);
                }
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} flex={1}>
          {atHomeActs.length > 0 && <ActsContainer labOrHome='labAndHome' acts={atHomeActs} patientActs={patientActs} onToggleAct={onToggleAct} />}
          {labOnlyActs.length > 0 && <ActsContainer labOrHome='labOnly' acts={labOnlyActs} patientActs={patientActs} onToggleAct={onToggleAct} />}
        </Box>
      </Box>
      <Box width='100px' display={isMobileView ? 'none' : 'flex'} justifyContent='center' alignItems='center' sx={{ transform: 'scale(0.8)' }}>
        {enableRemove && (
          <IconButton
            aria-label='delete'
            onClick={() => {
              if (patientActs.id) {
                onRemove(patientActs.id);
              }
            }}
          >
            <Close fontSize='large' />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default BookingPatientActs;
