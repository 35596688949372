import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import CustomButton from 'components/buttons/custom-button';
import CustomDialog from 'components/custom-dialog';
import { useIsMobileView } from 'hooks';
import { AppointmentMedicalAct } from 'models/appointment.medical.act.model';
import { RdvAnalyse } from 'models/appointment.model';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createAnonymousPatientsFromNumber, createPatientFromSelectablePerson } from 'utils/func/Appointment.func';
import { Props, SelectablePerson } from '..';
import BookingNavigation from './Booking.navigation';
import BookingPatientActs from './Booking.patient.acts';

const BookingActs: FC<Props> = ({ rdv, selectablePersons, medicalActs, setRdv, handleNext, setIntroductionStep }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView(1270);

  const [showMoreActsDialogBeforeNext, setShowMoreActsDialogBeforeNext] = useState<boolean>(false);
  const [showMoreActsDialog, setShowMoreActsDialog] = useState<boolean>(false);
  const [showConflictActsDialog, setShowConflictActsDialog] = useState<boolean>(false);
  const [showAddPersonDialog, setShowAddPersonDialog] = useState<boolean>(false);

  const remainingSelectablePersons = selectablePersons.filter((person) => !rdv.prescriptions.some((prescription) => prescription.patient.id === person.id));

  const handleToggleMedicalActToPatient = (prescriptionId: string, analyseId: number) => {
    if (rdv.slot) {
      setShowMoreActsDialogBeforeNext(true);
    }

    const prescription = rdv.prescriptions.find((prescription) => prescription.id === prescriptionId);

    if (prescription) {
      const analyseToToggle = prescription.analyses.find((analyse) => analyse.id === analyseId);

      setRdv((currentRdv) => {
        return {
          ...currentRdv,
          prescriptions: currentRdv.prescriptions.map((prescription) => {
            if (prescription.id === prescriptionId) {
              return {
                ...prescription,
                analyses: analyseToToggle
                  ? prescription.analyses.filter((analyse) => analyse.id !== analyseId)
                  : [...prescription.analyses, medicalActs.find((act) => act.id === analyseId) as AppointmentMedicalAct],
              };
            }

            return prescription;
          }),
        };
      });
    }
  };

  const addAnonymousPerson = () => {
    setRdv((currentRdv) => {
      return {
        ...currentRdv,
        prescriptions: [...currentRdv.prescriptions, ...createAnonymousPatientsFromNumber(1)],
      };
    });
  };

  const addSelectablePerson = (person: SelectablePerson) => {
    setRdv((currentRdv) => {
      return {
        ...currentRdv,
        prescriptions: [...currentRdv.prescriptions, createPatientFromSelectablePerson(person)],
      };
    });
  };

  const handleAddPatient = () => {
    if (rdv.slot) {
      setShowMoreActsDialogBeforeNext(true);
    }

    // Check if we can add a selectable person
    if (remainingSelectablePersons.length > 0) {
      setShowAddPersonDialog(true);
      return;
    }

    // If no, just add an anonymous patient
    addAnonymousPerson();
  };

  const handleRemovePatient = (prescriptionId: string) => {
    if (rdv.slot) {
      setShowMoreActsDialogBeforeNext(true);
    }

    setRdv((currentRdv) => {
      return {
        ...currentRdv,
        prescriptions: currentRdv.prescriptions.filter((prescription) => prescription.id !== prescriptionId),
      };
    });
  };

  const handleConflictActs = () => {
    // If there is only one patient, we can skip the conflict dialog
    if (rdv.prescriptions.length < 2) {
      handleNext();
      return;
    }

    const inCenterOnlyActsIndex = rdv.prescriptions.findIndex((prescription) => prescription.analyses.some((analyse) => analyse.only_center));

    // If there is no in center only act, we can skip the conflict dialog
    if (inCenterOnlyActsIndex < 0) {
      handleNext();
      return;
    }

    const remainingPrescriptions = rdv.prescriptions.filter((prescription, index) => index !== inCenterOnlyActsIndex);

    // If there is at least one patient with no center only act, show the conflict dialog
    if (remainingPrescriptions.some((prescription) => prescription.analyses.every((analyse) => !analyse.only_center))) {
      setShowConflictActsDialog(true);
      return;
    }

    // Else, handle next
    handleNext();
  };

  const { atHomeActs, labOnlyActs } = medicalActs.reduce(
    (acc, act) => {
      if (act.only_center) {
        acc.labOnlyActs.push(act);
      } else {
        acc.atHomeActs.push(act);
      }

      return acc;
    },
    { atHomeActs: [] as RdvAnalyse[], labOnlyActs: [] as RdvAnalyse[] },
  );

  return (
    <>
      <Box display='flex' flexDirection='column' justifyContent='center' flex={1}>
        <Box display='flex' flexDirection='column' flex={1}>
          <Box display='flex' flexDirection='column' flex={1}>
            <Box my={4}>
              <Typography variant='h2' textAlign='center'>
                {t('appointment.book.steps.introduction.acts.title')}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='column' alignSelf='center' width='80%' flex={1} mb={4}>
              <Box display='flex' flexDirection='column' flex={1}>
                <Box display='flex'>
                  <Box width='150px' />
                  <Box display={isMobileView ? 'none' : 'flex'} flex='1 1 50%'>
                    {atHomeActs.length > 0 && (
                      <Box flex={1}>
                        <Typography textAlign='center' fontWeight='500'>
                          {t('appointment.book.steps.introduction.acts.labAndHome')}
                        </Typography>
                      </Box>
                    )}
                    {labOnlyActs.length > 0 && (
                      <Box flex={1} color='#808080'>
                        <Typography textAlign='center' fontWeight='500'>
                          {t('appointment.book.steps.introduction.acts.labOnly')}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box width='100px' display={isMobileView ? 'none' : 'block'} />
                </Box>
                {rdv.prescriptions.map((patientActs, index) => (
                  <BookingPatientActs
                    key={patientActs.id}
                    patientActs={patientActs}
                    index={index}
                    atHomeActs={atHomeActs}
                    labOnlyActs={labOnlyActs}
                    onToggleAct={handleToggleMedicalActToPatient}
                    onRemove={handleRemovePatient}
                    enableRemove={rdv.prescriptions.length > 1}
                  />
                ))}
                <Box display='flex'>
                  <Box flex={1} padding={1} sx={{ boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A', borderRadius: '10px' }}>
                    <Button variant='outlined' fullWidth sx={{ fontWeight: '500' }} onClick={handleAddPatient}>
                      <Add fontSize='large' />
                      {t('appointment.book.steps.patients.unknownPatients.add.button')}
                    </Button>
                  </Box>
                  <Box width='100px' display={isMobileView ? 'none' : 'block'} />
                </Box>
              </Box>
              <BookingNavigation
                handleBack={() => setIntroductionStep(selectablePersons.length > 0 ? 'choose-patients' : 'choose-patient-number')}
                handleNext={() => {
                  showMoreActsDialogBeforeNext ? setShowMoreActsDialog(true) : handleConflictActs();
                }}
                disabledNext={!rdv.prescriptions.every((prescription) => prescription.analyses.length > 0)}
                forceMobileView={isMobileView}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <CustomDialog open={showMoreActsDialog}>
        <Box display='flex' flexDirection='column' gap={2} p={4}>
          <Typography variant='h3'>{t('appointment.book.steps.introduction.acts.dialog.title')}</Typography>
          <Typography fontSize='0.8rem'>{t('appointment.book.steps.introduction.acts.dialog.description')}</Typography>
          <Box display='flex' gap={2}>
            <CustomButton
              inverted
              onClick={() => {
                setRdv((currentRdv) => {
                  return {
                    ...currentRdv,
                    slot: null,
                  };
                });
                handleNext();
              }}
            >
              {t('appointment.book.action_buttons.next')}
            </CustomButton>
          </Box>
        </Box>
      </CustomDialog>
      <CustomDialog open={showConflictActsDialog}>
        <Box display='flex' flexDirection='column' gap={2} p={4}>
          <Typography variant='h3'>{t('appointment.book.steps.introduction.acts.conflict_dialog.title')}</Typography>
          <Typography fontSize='0.8rem'>{t('appointment.book.steps.introduction.acts.conflict_dialog.description')}</Typography>
          <Box display='flex' gap={2}>
            <CustomButton
              onClick={() => {
                setShowConflictActsDialog(false);
              }}
            >
              {t('appointment.book.steps.introduction.acts.conflict_dialog.change_acts_button')}
            </CustomButton>
            <CustomButton inverted onClick={handleNext}>
              {t('appointment.book.action_buttons.next')}
            </CustomButton>
          </Box>
        </Box>
      </CustomDialog>
      {selectablePersons.length > 0 && (
        <CustomDialog open={showAddPersonDialog}>
          <Box display='flex' flexDirection='column' gap={2} p={4}>
            <Typography variant='h3'>{t('appointment.book.steps.introduction.acts.add_person_dialog.title')}</Typography>
            <Box display='flex' gap={2}>
              {remainingSelectablePersons.map((person) => (
                <CustomButton
                  key={person.id}
                  onClick={() => {
                    addSelectablePerson(person);
                    setShowAddPersonDialog(false);
                  }}
                >
                  {person.first_name} {person.last_name}
                </CustomButton>
              ))}
              <CustomButton
                onClick={() => {
                  addAnonymousPerson();
                  setShowAddPersonDialog(false);
                }}
              >
                {t('appointment.book.steps.introduction.buttons.addPerson')}
              </CustomButton>
            </Box>
            <Box>
              <CustomButton onClick={() => setShowAddPersonDialog(false)}>{t('appointment.book.action_buttons.cancel')}</CustomButton>
            </Box>
          </Box>
        </CustomDialog>
      )}
    </>
  );
};

export default BookingActs;
