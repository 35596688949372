import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';

const CustomTextField: FC<TextFieldProps> = (props) => {
  return (
    <TextField
      {...props}
      sx={{
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: '#212121',
          borderWidth: '2px',
          lineHeight: '1em',
        },
        ...props.sx,
      }}
      InputLabelProps={{
        sx: {
          padding: 0,
          fontWeight: '500',
          color: '#808080',
          '&[data-shrink="false"]': {
            color: '#212121',
          },
        },
        ...props.InputLabelProps,
      }}
    />
  );
};

export default CustomTextField;
