import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';

type CustomButtonProps = ButtonProps & {
  inverted?: boolean;
  hasError?: boolean;
};
const CustomButton: FC<CustomButtonProps> = ({ inverted, hasError, ...props }) => {
  const sx = {
    borderColor: hasError ? 'red' : ( inverted ? '#46BFF5' : 'initial' ),
    whiteSpace: 'nowrap',
    fontWeight: '500',
    color: inverted ? '#212121' : 'initial',
    backgroundColor: inverted ? '#46BFF5' : 'initial',
    ':hover': {
      color: '#ffffff',
      borderColor: '#212121',
      backgroundColor: '#212121',
    },
    ':selected': {
      borderColor: '#46BFF5',
      color: '#212121',
      backgroundColor: '#46BFF5',
    },
    ':disabled': {
      borderColor: 'rgba(0,0,0,.24)',
    },
    ...props.sx,
  } satisfies ButtonProps['sx'];

  return (
    <Button {...props} variant='outlined' sx={sx} onClick={props.onClick}>
      {props.children}
    </Button>
  );
};

export default CustomButton;
