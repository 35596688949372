import { Box, Button, Typography } from '@mui/material';
import api from 'api';
import ContextLink from 'components/_base/ContextLink';
import CustomDialog from 'components/custom-dialog';
import { intlFormat } from 'date-fns';
import { useIsMobileView, useLocale } from 'hooks';
import { RdvPrescription } from 'models/appointment.model';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Marker, StaticGoogleMap } from 'react-static-google-map';
import { getUTCDate } from 'utils/func/Appointment.func';
import { getAppointmentTempPatientName } from 'utils/func/Person.func';
import { Props } from '..';
import config from '../../../../config';
import BookingNavigation from './Booking.navigation';
import Nl2br from 'components/utils/nl2br';
import { AxiosError } from 'axios';
import ucfirst from "ucfirst-js";


type PatientSummaryProps = {
  index: number;
  prescription: RdvPrescription;
};

const PatientSummary: FC<PatientSummaryProps> = ({ index, prescription }) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  return (
    <Box
      display='flex'
      flexDirection={isMobileView ? 'column' : 'row'}
      alignItems={isMobileView ? 'initial' : 'center'}
      gap={3}
      sx={{
        borderRadius: '10px',
        boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A',
      }}
      p={2}
      width={isMobileView ? 'auto' : '700px'}
    >
      <Box display='flex' flexDirection='column' flex={1} width={isMobileView ? 'auto' : '50%'}>
        <Box>
          <Typography fontSize='1rem' fontWeight='500'>
            {getAppointmentTempPatientName(prescription.patient, index, t)}
          </Typography>
          <Typography fontSize='0.7rem !important' fontWeight='500' mt={1}>
            {t('appointment.book.steps.summary.medical_acts',{count: prescription.analyses.length})}
          </Typography>
          <Box display='flex' flexWrap='wrap' gap={2} mt={1}>
            {prescription.analyses.map((analyse) => (
              <Box
                key={analyse.id}
                sx={{
                  borderRadius: 1,
                  backgroundColor: '#808080',
                  color: '#FFFFFF',
                  fontWeight: '500',
                  padding: '8px 24px',
                }}
              >
                {analyse.name}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box flex={1} width={isMobileView ? 'auto' : '50%'}>
        <Box display='flex' flexDirection='column' gap={2} paddingLeft={isMobileView ? 0 : 2}>
            {isMobileView ? (
              <Typography fontSize='0.7rem !important' fontWeight='500' mt={1}>
                {t(`appointment.book.steps.summary.nb_prescriptions`,{count: prescription.ordonnances.length})}
              </Typography>
            ) : (
              <Typography variant='h3' fontSize='1rem' fontWeight='500'>
                {t(`appointment.book.steps.summary.nb_prescriptions`,{count: prescription.ordonnances.length})}
              </Typography>
            )}
        </Box>
      </Box>
    </Box>
  );
};

type Status = 'slot-error' | 'internal-error' | 'connected-success' | 'not-connected-success';

type ConfirmDialogProps = {
  open: boolean;
  status: Status;
  newRdv: () => void;
  chooseSlot: () => void;
};

const ConfirmDialog: FC<ConfirmDialogProps> = ({ open, status, newRdv, chooseSlot }) => {
  const { t } = useTranslation();

  return (
    <CustomDialog open={open}>
      <Box display='flex' flexDirection='column' alignItems='center' gap={2} p={4}>
        <Typography variant='h2' textAlign='center'>
          {t(`appointment.book.steps.summary.${status}.title`)}
        </Typography>
        <Typography variant='body1' textAlign='center'>
          <Nl2br text={t(`appointment.book.steps.summary.${status}.description`)} />
        </Typography>
        {status.endsWith('success') && (
          <Box display='flex' gap={2}>
            <ContextLink to='HOME'>
              <Button variant='outlined'>{t('appointment.book.action_buttons.finish')}</Button>
            </ContextLink>
            <Button variant='contained' onClick={newRdv}>
              {t(`appointment.book.steps.summary.buttons.new_appointment`)}
            </Button>
          </Box>
        )}
        {status === 'slot-error' && (
          <Button variant='contained' onClick={chooseSlot}>
            {t(`appointment.book.steps.summary.buttons.change_slot`)}
          </Button>
        )}
        {status === 'internal-error' && (
          <Button variant='contained' onClick={newRdv}>
            {t(`appointment.book.steps.summary.buttons.retry`)}
          </Button>
        )}
      </Box>
    </CustomDialog>
  );
};

const BookingSummaryStep: FC<Props> = ({ rdv, selectablePersons, handleBack, resetRdv, resetStepper, goToStep, setIntroductionStep, setMemoizedSlots }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const isMobileView = useIsMobileView();

  const [loading, setLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<{ status: Status } | null>(null);

  const handleSubmit = async () => {
    setLoading(true);

    try {
      await api.appointments.bookAppointment(rdv, locale);
      const status = selectablePersons.length > 0 ? 'connected-success' : 'not-connected-success';
      setResponse({ status });
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data.code === 'SLOT_NOT_AVAILABLE') {
        setResponse({ status: 'slot-error' });
      } else {
        setResponse({ status: 'internal-error' });
      }
    } finally {
      setLoading(false);
      setMemoizedSlots(new Map());
    }
  };

  const duration = rdv.prescriptions.reduce((acc, prescription) => {
    // @see #16 If appointment in center,
    // we only count the duration of the medical acts that are only done at the center
    return acc + prescription.analyses.reduce((acc, analyse) => (rdv.center && !analyse.only_center ? acc : analyse.duration_act + analyse.installation_duration), 0);
  }, 0);

  return (
    <>
      <Box display='flex' flexDirection='column' justifyContent='center' flex={1}>
        <Box display='flex' flexDirection='column' flex={1}>
          <Box display='flex' flexDirection='column' flex={1} justifyContent='center'>
            <Box my={4} justifyContent='center'>
              <Typography variant='h2' textAlign='center'>
                {t('appointment.book.steps.summary.title')}
              </Typography>
            </Box>
            <Box display='flex' flexDirection='column' gap={2} flex={1} alignSelf='center' width={isMobileView ? 'auto' : '700px'}>
              <Box
                display='flex'
                flexDirection={isMobileView ? 'column' : 'row'}
                alignItems={isMobileView ? 'initial' : 'center'}
                gap={3}
                sx={
                  isMobileView
                    ? {}
                    : {
                        borderRadius: '10px',
                        boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A',
                      }
                }
                p={isMobileView ? 0 : 2}
                width={isMobileView ? 'auto' : '700px'}
              >
                <Box display='flex' gap={2} width={isMobileView ? 'auto' : '50%'}>
                  <Box sx={{ border: '2px solid #212121', borderRadius: '4px' }} width='104px' height='104px'>
                    <StaticGoogleMap size='200x200' zoom='18' apiKey={config.googleMapsApiKey} width='100px' height='100px'>
                      <Marker location={(rdv.center ? rdv.center.coord.coordinates[1] : rdv.coordInfo?.lat) + ',' + (rdv.center ? rdv.center.coord.coordinates[0] : rdv.coordInfo?.lon)} color='red' />
                    </StaticGoogleMap>
                  </Box>
                  <Box display='flex' flexDirection='column' flex={1} width={isMobileView ? 'auto' : '50%'}>
                    <Box>
                      {rdv.center?.name && (
                        <Typography fontSize='1rem' fontWeight='700'>
                          {rdv.center.name}
                        </Typography>
                      )}
                      <Typography fontSize='1rem' fontWeight='500'>
                        {rdv.center ? rdv.center.address : rdv.coordInfo?.display_name}
                      </Typography>
                      {!rdv.center && (
                        <Typography fontSize='0.8rem' fontWeight='500'>
                          {rdv.coordInfo?.postcode}, {rdv.coordInfo?.city}
                        </Typography>
                      )}
                      {!rdv.center && rdv.coordInfo?.comment && <Typography fontSize='0.8rem'>{rdv.coordInfo?.comment}</Typography>}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={
                    isMobileView
                      ? {
                          borderRadius: '10px',
                          boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A',
                        }
                      : {}
                  }
                >
                  <Box display='flex' flexDirection='column' gap={2} padding={2}>
                    <Box>
                      <Typography variant='h3' fontSize='1rem' fontWeight='700' textAlign='center'>
                        {ucfirst(intlFormat(
                          new Date(rdv.slot!),
                          {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'long',
                          },
                          { locale },
                        ))}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant='h3' fontSize='1rem' fontWeight='500' textAlign='center'>
                        <Nl2br
                          text={t(`appointment.book.steps.summary.${rdv.center ? 'center_' : ''}slot`, {
                            replace: {
                              start: intlFormat(getUTCDate(new Date(rdv.slot!)), { hour: 'numeric', minute: 'numeric' }, { locale }),
                              end: intlFormat(getUTCDate(new Date(new Date(rdv.slot!).getTime() + 1000 * 60 * 30)), { hour: 'numeric', minute: 'numeric' }, { locale }),
                            },
                          })}
                        />
                      </Typography>
                      <Typography variant='h3' fontSize='1rem' fontWeight='500' textAlign='center'>
                        <Nl2br text={t(`appointment.book.steps.summary.duration`, { replace: { duration } })} />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box mt={isMobileView ? 2 : 3}>
                <Typography variant='h2' textAlign='center'>
                  {t('appointment.book.steps.summary.patients', { count: rdv.prescriptions.length })}
                </Typography>
              </Box>
              {rdv.prescriptions.map((prescription, index) => (
                <PatientSummary key={prescription.id} index={index} prescription={prescription} />
              ))}
              <BookingNavigation handleBack={handleBack} handleNext={handleSubmit} nextElement={t('common.action.confirm')} disabledNext={loading} loadingNext={loading} />
            </Box>
          </Box>
        </Box>
      </Box>
      {response && (
        <ConfirmDialog
          open={!loading && !!response}
          status={response.status}
          newRdv={() => {
            resetRdv();
            setIntroductionStep(selectablePersons.length > 0 ? 'choose-patients' : 'choose-patient-number');
            resetStepper();
          }}
          chooseSlot={() => goToStep(1)}
        />
      )}
    </>
  );
};

export default BookingSummaryStep;
