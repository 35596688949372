import { AppointmentPatient, Rdv, RdvCoordInfo, RdvPatient, RdvPrescription } from 'models/appointment.model';
import { Patient } from 'models/patients.model';
import { nanoid } from 'nanoid';
import { SelectablePerson } from 'pages/patient/appointment';
import { convertDate } from './Date.func';
import { Address } from 'models/profile.model';

/**
 * Returns the end time of the given slot depending on the given duration in H:mm format
 * @param {string} slotStartTime time in H:mm format (ex. "06:30")
 * @param {string} slotDuration duration in period time format (ex. "PT00H30M")
 * @returns {string} end time in H:mm format (ex. "07:00")
 */
export const getSlotEndTime = (slotStartTime: string, slotDuration: string) => {
  const now = new Date();
  now.setHours(parseInt(slotStartTime.split(':')[0]) + parseInt(slotDuration.split('H')[0].split('PT')[1]));
  now.setMinutes(parseInt(slotStartTime.split(':')[1]) + parseInt(slotDuration.split('H')[1].split('M')[0]));
  return convertDate(now, false, 'H:mm');
};

export const canMakeAppointmentForPatient = (person: Patient) =>
  (!!person.first_name && person.first_name !== '' && person.first_name !== null) ||
  (!!person.last_name && person.last_name !== '' && person.last_name !== null && !!person.birth_name && person.birth_name !== '' && person.birth_name !== null);

export const canMakeAppointmentForAppointmentPatient = (person: AppointmentPatient) =>
  !!person.first_name &&
  person.first_name !== '' &&
  person.first_name !== null &&
  !!person.last_name &&
  person.last_name !== '' &&
  person.last_name !== null &&
  !!person.birth_name &&
  person.birth_name !== '' &&
  person.birth_name !== null;

export const createAnonymousPatientsFromNumber = (anonymousPatientNumber: number): RdvPrescription[] => {
  return Array<RdvPrescription | null>(anonymousPatientNumber)
    .fill(null)
    .map(() => ({
      id: nanoid(),
      patient: Object.fromEntries(REQUIRED_FIELDS.map((field) => [field, ''])) as unknown as RdvPatient,
      analyses: [],
      ordonnances: [],
    }));
};

export const createPatientFromSelectablePerson = ({ patient_id, id, first_name, birth_name, last_name, birth_date, gender, email, mobile_phone, phone }: SelectablePerson): RdvPrescription => ({
  id: patient_id ?? nanoid(),
  analyses: [],
  ordonnances: [],
  patient: {
    id,
    firstname: first_name || '',
    birthname: birth_name || '',
    lastname: last_name || '',
    birthdate: birth_date || '',
    email: email || '',
    verifyemail: email || '',
    phone: mobile_phone || phone || '',
    gender: gender || '',
  },
});

const REQUIRED_FIELDS = ['gender', 'firstname', 'birthname', 'birthdate', 'email', 'verifyemail', 'phone'] satisfies (keyof RdvPatient)[];
const CONNECTED_REQUIRED_FIELDS = ['email', 'verifyemail', 'phone'] satisfies (keyof RdvPatient)[];

export const getRequiredFields = (isConnected: boolean) => (isConnected ? CONNECTED_REQUIRED_FIELDS : REQUIRED_FIELDS);

export const isRequiredFields = (field: keyof RdvPatient, isConnected: boolean) => getRequiredFields(isConnected).includes(field as any);

export function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

export function isCenterOnly(rdv: Rdv) {
  return rdv.prescriptions.some((prescription) => prescription.analyses.some((analyse) => analyse.only_center));
}

// Date utils
export const getFirstDayOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth(), 1);
export const getLastDayOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);

export const getUTCDate = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() + offset * 60 * 1000);
  return newDate;
};

export const formatDate = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);
  return newDate.toISOString().split('T')[0];
};

// Address utils
export const getFormattedAddress = (address: Address) => {
  return `${address.number || ''} ${address.street || ''} ${address.zip_code || ''}, ${address.city || ''}, ${address.country || ''}`.trim();
};

export const getCoordInfoFromGeolocation = (geolocation: google.maps.GeocoderResult) => {
  return {
    display_name: geolocation.formatted_address,
    city: geolocation.address_components.find((component) => component.types.includes('locality'))?.long_name || '',
    country: geolocation.address_components.find((component) => component.types.includes('country'))?.long_name || '',
    country_code: geolocation.address_components.find((component) => component.types.includes('country'))?.short_name || '',
    street_number: geolocation.address_components.find((component) => component.types.includes('street_number'))?.long_name || '',
    street_name: geolocation.address_components.find((component) => component.types.includes('route'))?.long_name || '',
    postcode: geolocation.address_components.find((component) => component.types.includes('postal_code'))?.long_name || '',
    state: geolocation.address_components.find((component) => component.types.includes('administrative_area_level_1'))?.long_name || '',
    lat: geolocation.geometry.location.lat(),
    lon: geolocation.geometry.location.lng(),
    comment: '',
  } satisfies RdvCoordInfo;
};

export const getSlotsKey = (startDate: Date, endDate: Date, rdv: Rdv) =>
  `${startDate.toISOString()}-${endDate.toISOString()};${rdv.center?.id ?? rdv.coordInfo?.display_name};${rdv.prescriptions
    .map((prescription) => prescription.analyses.map((analyse) => analyse.id))
    .flat()
    .join('-')}`;
