import { Box, Button, CircularProgress, Typography } from '@mui/material';
import api from 'api';
import { AxiosError } from 'axios';
import ContextLink from 'components/_base/ContextLink';
import { intlFormat } from 'date-fns';
import { useLocale } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getUTCDate } from 'utils/func/Appointment.func';

type AppointmentToCancel = {
  id: number;
  start: string;
};

const AppointmentCancelPage: FC = () => {
  const { t } = useTranslation();
  const { token } = useParams();

  const locale = useLocale();

  const [loadingAppointment, setLoadingAppointment] = useState<boolean>(false);
  const [appointment, seAppointment] = useState<AppointmentToCancel | null>(null);
  const [appointmentError, setAppointmentError] = useState<string>('');

  const [loadingCancel, setLoadingCancel] = useState<boolean>(false);
  const [cancelError, setCancelError] = useState<string>('');
  const [cancelSuccess, setCancelSuccess] = useState<boolean>(false);

  const handleCancel = async (id: number) => {
    setLoadingCancel(true);
    try {
      await api.appointments.cancelAppointment(id);
      setCancelSuccess(true);
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        setCancelError(t('appointment.cancel.error.cancelation'));
      }
    } finally {
      setLoadingCancel(false);
    }
  };

  useEffect(() => {
    const fetchAppointment = async (cancelToken: string) => {
      setLoadingAppointment(true);
      try {
        const data = await api.appointments.getAppointmentsByToken(cancelToken);
        seAppointment(data);
      } catch (error: any) {
        if (error instanceof AxiosError && error.response?.status === 404) {
          setAppointmentError(t('appointment.cancel.error.loading'));
        } else {
          setAppointmentError(t('appointment.cancel.error.unknown'));
        }
      } finally {
        setLoadingAppointment(false);
      }
    };

    if (token) {
      fetchAppointment(token);
    }
  }, [token, t]);

  if (appointmentError) {
    return (
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' flex={1} gap={4}>
        <Typography variant='body1'>{appointmentError}</Typography>
        <ContextLink to='HOME'>
          <Button variant='contained'>{t('appointment.cancel.action.home')}</Button>
        </ContextLink>
      </Box>
    );
  }

  if (cancelError) {
    return (
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' flex={1} gap={4}>
        <Typography variant='body1'>{cancelError}</Typography>
        <ContextLink to='HOME'>
          <Button variant='contained'>{t('appointment.cancel.action.home')}</Button>
        </ContextLink>
      </Box>
    );
  }

  if (cancelSuccess) {
    return (
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' flex={1} gap={4}>
        <Typography variant='h1'>{t('appointment.cancel.success')}</Typography>
        <ContextLink to='HOME'>
          <Button variant='contained'>{t('appointment.cancel.action.home')}</Button>
        </ContextLink>
      </Box>
    );
  }

  if (loadingAppointment || !appointment) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' flex={1}>
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={4} flex={1}>
      <Typography variant='h1' textAlign='center'>
        {t('appointment.cancel.title')}
      </Typography>
      <Typography variant='subtitle1' textAlign='center'>
        {t('appointment.cancel.subtitle')}
      </Typography>
      <Typography variant='h3' textAlign='center'>
        {intlFormat(
          getUTCDate(new Date(appointment.start)),
          {
            weekday: 'long',
            day: 'numeric',
            month: 'long',
            hour: 'numeric',
            minute: 'numeric',
          },
          { locale },
        )}
      </Typography>
      <Box display='flex' gap={2}>
        <Button variant='contained' onClick={() => handleCancel(appointment.id)} disabled={loadingCancel}>
          {t('appointment.cancel.action.confirm')}
        </Button>
        <ContextLink to='HOME'>
          <Button variant='outlined'>{t('appointment.cancel.action.home')}</Button>
        </ContextLink>
      </Box>
    </Box>
  );
};

export default AppointmentCancelPage;
