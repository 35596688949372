import { PickersCalendarHeader, PickersCalendarHeaderProps } from '@mui/x-date-pickers';
import { FC } from 'react';

const CustomCalendarHeader: FC<PickersCalendarHeaderProps<Date>> = (props) => {
  return (
    <PickersCalendarHeader
      {...props}
      sx={{
        ...props.sx,
        fontSize: '2em',
        position: 'relative',
        '& .MuiPickersCalendarHeader-labelContainer': {
          position: 'absolute',
          left: '50%',
          whiteSpace: 'nowrap',
          transform: 'translateX(-50%)',
          zIndex: 1,
        },
        '& .MuiPickersArrowSwitcher-root': {
          position: 'absolute',
          justifyContent: 'space-between',
          left: 0,
          right: 0,
        },
        '& .MuiPickersCalendarHeader-label': {
          fontWeight: '500',
          textTransform: 'capitalize',
        },
      }}
    />
  );
};

export default CustomCalendarHeader;
