import { Search } from '@mui/icons-material';
import { InputAdornment, Paper, UseAutocompleteProps } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { debounce } from '@mui/material/utils';
import parse from 'autosuggest-highlight/parse';
import { CSSProperties, Dispatch, FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const autocompleteService = { current: null } as { current: google.maps.places.AutocompleteService | null };

type DebounceArgsType = Parameters<google.maps.places.AutocompleteService['getPlacePredictions']>;

type BookingLocationAutocompleteProps = {
  selectedPlace: google.maps.places.AutocompletePrediction | null;
  locationInputRef: React.RefObject<HTMLInputElement>;
  setSelectedPlace: Dispatch<google.maps.places.AutocompletePrediction | null>;
  onAddressChange: (address: string) => void;
  resetPreselectedAddress: () => void;
};

const BookingLocationAutocomplete: FC<BookingLocationAutocompleteProps> = ({ selectedPlace, locationInputRef, setSelectedPlace, onAddressChange, resetPreselectedAddress }) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<readonly google.maps.places.AutocompletePrediction[]>([]);
  const [shrink, setShrink] = useState(!!selectedPlace);

  const fetch = useMemo(
    () =>
      debounce<(...args: DebounceArgsType) => void>((request, callback) => {
        if (!autocompleteService.current) return;
        autocompleteService.current!.getPlacePredictions(
          {
            ...request,
            types: ['address'],
            componentRestrictions: { country: ['lu'] },
          },
          callback,
        );
      }, 400),
    [],
  );

  // Fired when a value is selected from the dropdown
  const handleChange: UseAutocompleteProps<google.maps.places.AutocompletePrediction, false, false, false>['onChange'] = (_e, newValue) => {
    // Set / unset the current value
    setSelectedPlace(newValue);

    // Reset preselected address (home/work)
    resetPreselectedAddress();

    // Update the options
    setOptions(newValue ? [newValue, ...options] : options);

    // Reset address
    if (!newValue) {
      onAddressChange('');
      return;
    }

    // Set Address
    onAddressChange(newValue.description);
  };

  useEffect(() => {
    let active = true;

    // Autocomplete Service
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(selectedPlace ? [selectedPlace] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions: readonly google.maps.places.AutocompletePrediction[] = [];

        if (selectedPlace) {
          newOptions = [selectedPlace];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [selectedPlace, inputValue, fetch]);

  useEffect(() => {
    setShrink(!!selectedPlace);
  }, [selectedPlace]);

  return (
    <Autocomplete
      filterOptions={(x) => x}
      id='location-autocomplete'
      fullWidth
      getOptionLabel={(option) => option.description}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={selectedPlace}
      noOptionsText={
        <Typography pl={2} px={1}>
          {t('appointment.book.steps.search.field.no_options')}
        </Typography>
      }
      onChange={handleChange}
      onInputChange={(_e, newInputValue) => {
        setShrink(true);
        setInputValue(newInputValue);
      }}
      onFocus={() => setShrink(true)}
      onBlur={() => {
        if (!selectedPlace) {
          setShrink(false);
        }
      }}
      PaperComponent={({ children, ...props }) => (
        <Paper
          {...props}
          sx={{
            boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A',
            borderRadius: '32px',
            mt: 2,
          }}
        >
          {children}
        </Paper>
      )}
      ListboxProps={{
        sx: {
          px: 1,
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: '#212121',
              borderWidth: '2px',
            },
          }}
          // @see MYLAB-513 Add unbreakable spaces as right padding for white background behind label
          label={t('appointment.book.steps.search.field.label')+"\x00\xA0\x00\xA0"}
          fullWidth
          InputLabelProps={{
            shrink: shrink,
            sx: {
              fontWeight: '500',
              padding: '0 0.5rem',
              color: '#808080',
              '&[data-shrink="false"]': {
                color: '#212121',
                transform: 'translate(2.5em, 1em)',
              },
            },
          }}
          inputRef={locationInputRef}
          InputProps={{
            ...params.InputProps,
            sx: {
              borderRadius: '100px',
              fontWeight: '500',
            },
            startAdornment: (
              <InputAdornment position='start'>
                <Search sx={{ color: '#212121' }} />
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings || [];

        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length]),
        );

        const first = props['data-option-index' as keyof typeof props] === 0;
        const last = props['data-option-index' as keyof typeof props] === options.length - 1;

        const listElementStyle = {
          ...props.style,
          borderBottom: last ? 'none' : '1px solid #707070',
          borderTopLeftRadius: first ? '32px' : '0px',
          borderTopRightRadius: first ? '32px' : '0px',
          borderBottomLeftRadius: last ? '32px' : '0px',
          borderBottomRightRadius: last ? '32px' : '0px',
          padding: '1rem 1rem',
          paddingLeft: '2rem',
        } satisfies CSSProperties;

        return (
          <li {...props} style={listElementStyle}>
            <Grid container alignItems='center'>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                {parts.map((part, index) => (
                  <Box key={index} component='span' sx={{ fontWeight: part.highlight ? '500' : 'regular' }}>
                    {part.text}
                  </Box>
                ))}
                {', '}
                {option.structured_formatting.secondary_text}
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default BookingLocationAutocomplete;
