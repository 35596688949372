import { Box, Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import config from 'config';
import { intlFormat } from 'date-fns';
import { useLocale } from 'hooks';
import { RdvCenter } from 'models/appointment.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getUTCDate } from 'utils/func/Appointment.func';

type CenterProps = {
  center: RdvCenter;
  selected?: boolean;
  onSelect: () => void;
  selectSlot: (slot: string) => void;
};

const Center: FC<CenterProps> = ({ center, selected, onSelect, selectSlot }) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const nextAvailableSlot = center.nextAvailableSlot && center.nextAvailableSlot.nbrSlot > 0 ? new Date(center.nextAvailableSlot.start) : null;

  return (
    <Card sx={{ width: '700px', display: 'flex', gap: 2, padding: 2 }} raised={false}>
      {center.imageUrl ? (
        <CardMedia component='img' sx={{ width: '100px' }} image={`${config.myRdvBaseUrl}${center.imageUrl}`} alt='Center'></CardMedia>
      ) : (
        <Box sx={{ width: '100px', height: '100px', backgroundColor: '#cccccc' }} />
      )}
      <CardContent sx={{ width: '100%' }}>
        <Box display='flex' flexDirection='column' height='100%'>
          {center.name && <Typography fontWeight='bold'>{center.name}</Typography>}
          <Typography fontWeight='bold'>{center.address}</Typography>
          <Typography>{(center.distance / 1000).toFixed(1)} km</Typography>
          <Box display='flex' flex={1} alignSelf='flex-end'>
            <Box display='flex' flexDirection='column' alignItems='flex-end'>
              {nextAvailableSlot && (
                <Button
                  onClick={() => {
                    selectSlot(nextAvailableSlot.toISOString());
                    onSelect();
                  }}
                >
                  {t('appointment.book.steps.search.centers.book', {
                    replace: {
                      date: intlFormat(
                        getUTCDate(nextAvailableSlot),
                        {
                          weekday: 'long',
                          day: 'numeric',
                          month: 'long',
                          hour: 'numeric',
                          minute: 'numeric',
                        },
                        { locale },
                      ),
                    },
                  })}
                </Button>
              )}
              <Button
                onClick={() => {
                  selectSlot('');
                  onSelect();
                }}
              >
                {t('appointment.book.steps.search.centers.select_button')}
              </Button>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

type BookingSearchLaboratoriesProps = {
  centers: RdvCenter[];
  selectedCenter: RdvCenter | null;
  selectCenter: (center: RdvCenter) => void;
  selectSlot: (slot: string) => void;
};

export const BookingSearchLaboratories: FC<BookingSearchLaboratoriesProps> = ({ centers, selectedCenter, selectCenter, selectSlot }) => {
  const { t } = useTranslation();
  return (
    <Box bgcolor='rgb(182, 229, 249)' width='100%' flex={1}>
      <Box my={2}>
        <Typography variant='h2' textAlign='center'>
          {t('appointment.book.steps.search.centers.title')}
        </Typography>
      </Box>
      <Box display='flex' flexDirection='column' alignItems='center' gap={2} py={2}>
        {centers.map((center) => (
          <Center key={center.id} center={center} selected={center.id === selectedCenter?.id} onSelect={() => selectCenter(center)} selectSlot={selectSlot} />
        ))}
      </Box>
    </Box>
  );
};
