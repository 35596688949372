import { Box } from '@mui/material';
import { FC } from 'react';
import { Props } from '../index';
import BookingActs from './Booking.acts';
import BookingPatientsNumber from './Booking.patients.number';
import BookingPatientsSelect from './Booking.patients.select';

const BookingIntroductionStep: FC<Props> = (props) => {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' flex={1}>
      {props.introductionStep === 'choose-patient-number' ? (
        <BookingPatientsNumber {...props} />
      ) : props.introductionStep === 'choose-patients' ? (
        <BookingPatientsSelect {...props} />
      ) : (
        <BookingActs {...props} />
      )}
    </Box>
  );
};

export default BookingIntroductionStep;
