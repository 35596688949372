import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type BookingNavigationProps = {
  showBack?: boolean;
  disabledBack?: boolean;
  loadingBack?: boolean;
  showNext?: boolean;
  disabledNext?: boolean;
  loadingNext?: boolean;
  backElement?: ReactNode;
  nextElement?: ReactNode;
  fixed?: boolean;
  positionBack?: string;
  positionNext?: string;
  forceMobileView?: boolean;
  handleBack?: () => void;
  handleNext?: () => void;
};

const BookingNavigation: FC<BookingNavigationProps> = ({
  showBack = true,
  disabledBack = false,
  loadingBack = false,
  showNext = true,
  disabledNext = false,
  loadingNext = false,
  backElement,
  nextElement,
  fixed = true,
  positionBack,
  positionNext,
  forceMobileView = false,
  handleBack,
  handleNext,
}) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView(1360) || forceMobileView;

  const justifyContent = showBack && showNext ? 'space-between' : showNext ? 'flex-end' : 'flex-start';

  const sxBack = !fixed || isMobileView ? {} : { position: 'fixed', top: positionBack ?? '50%', left: '10%' };
  const sxNext = !fixed || isMobileView ? {} : { position: 'fixed', top: positionNext ?? '50%', right: '10%' };

  return (
    <Box display='flex' justifyContent={justifyContent} paddingY={2}>
      {showBack && (
        <LoadingButton variant='outlined' onClick={handleBack} disabled={disabledBack} loading={loadingBack} sx={sxBack}>
          {backElement ?? t('appointment.book.action_buttons.previous')}
        </LoadingButton>
      )}
      {showNext && (
        <LoadingButton variant='contained' onClick={handleNext} disabled={disabledNext} loading={loadingNext} sx={sxNext}>
          {nextElement ?? t('appointment.book.action_buttons.next')}
        </LoadingButton>
      )}
    </Box>
  );
};

export default BookingNavigation;
