import { Box, Button, CircularProgress, List, ListItem, Typography } from '@mui/material';
import API from 'api';
import config from 'config';
import { useIsMobileView } from 'hooks';
import { RdvCenter } from 'models/appointment.model';
import { Address } from 'models/profile.model';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCoordInfoFromGeolocation, getFormattedAddress, isCenterOnly, loadScript } from 'utils/func/Appointment.func';
import { AddressType, Props } from '..';
import CustomDialog from '../../../../components/custom-dialog';
import BookingLocationAutocomplete from './Booking.location.autocomplete';
import BookingNavigation from './Booking.navigation';
import PatientKnownAddresses from './Booking.patient.known.addresses';
import { BookingSearchLaboratories } from './Booking.search.laboratories';
import BookingSearchSelectedAddress from './Booking.search.selected.address';
import Nl2br from 'components/utils/nl2br';

const geocoderService = { current: null } as { current: google.maps.Geocoder | null };

const BookingSearchStep: FC<Props> = ({
  rdv,
  patientHomeAddress,
  patientWorkAddress,
  selectedPlace,
  selectedAddressType,
  memoizedSlots,
  selectedDate,
  setRdv,
  setSelectedPlace,
  handleNext,
  handleBack,
  setSelectedAddressType,
  setMemoizedSlots,
  setSelectedDate,
}) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  // @see #16 - Only fetch slots for in-lab medical acts
  const inLabMedicalActs = useMemo(
    () =>
      rdv.prescriptions
        .map((prescription) => prescription.analyses)
        .flat()
        .filter((analysis) => analysis.only_center),
    [rdv.prescriptions],
  );

  const [isGoogleMapsScriptAdded, setIsGoogleMapsScriptAdded] = useState(false);
  const [loadingStrictAddressDialog, setLoadingStrictAddressDialog] = useState<boolean>(false);
  const [nearestCenters, setNearestCenters] = useState<RdvCenter[]>([]);
  const [loadingNearestCenters, setLoadingNearestCenters] = useState<boolean>(false);
  const [isCenterPreselected, setIsCenterPreselected] = useState<boolean>(false);
  const [loadingCenter, setLoadingCenter] = useState<boolean>(inLabMedicalActs.length > 0 && !isCenterPreselected);

  const locationInputRef = useRef<HTMLInputElement>(null);

  if (typeof window !== 'undefined' && !isGoogleMapsScriptAdded) {
    if (!document.querySelector('#google-maps')) {
      loadScript(`https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&libraries=places,geocoding&callback=Function.prototype`, document.querySelector('head'), 'google-maps');
    }

    setIsGoogleMapsScriptAdded(true);
  }

  const selectSearchedAddress = (address: string) => {
    if (!address) {
      setSelectedAddressType(null);

      setRdv((currentRdv) => {
        return { ...currentRdv, coordInfo: null };
      });
      // Undo strict address loading
      setLoadingStrictAddressDialog(false);
      return;
    }

    geocoderService.current?.geocode({ address: address }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const geolocation = results[0];
        const coordInfo = getCoordInfoFromGeolocation(geolocation);

        setSelectedAddressType(null);

        setRdv((currentRdv) => {
          return { ...currentRdv, coordInfo: coordInfo };
        });
        // Load strict address from check
        setLoadingStrictAddressDialog(!(coordInfo.street_number.length > 0));
      }
    });
  };

  const selectPatientKnownAddress = ({ type, address }: { type: AddressType; address: Address }) => {
    // If we select same type than current one, toggle it
    if (type === selectedAddressType) {
      setSelectedAddressType(null);

      setRdv((currentRdv) => {
        return { ...currentRdv, coordInfo: null };
      });
      // Undo strict address loading
      setLoadingStrictAddressDialog(false);

      return;
    }

    geocoderService.current?.geocode({ address: getFormattedAddress(address) }, (results, status) => {
      if (status === 'OK' && results && results.length > 0) {
        const geolocation = results[0];
        const coordInfo = getCoordInfoFromGeolocation(geolocation);

        setSelectedAddressType(type);

        setRdv((currentRdv) => {
          return { ...currentRdv, coordInfo: coordInfo };
        });
        // Load strict address from check
        setLoadingStrictAddressDialog(!(coordInfo.street_number.length > 0));
      }
    });
  };

  const selectSlot = (slot: string) => {
    if (slot) {
      setSelectedDate(new Date(slot));
    }
    setRdv((currentRdv) => {
      return { ...currentRdv, slot: slot };
    });
  };

  const handleSelectCenter = useCallback(
    (center: RdvCenter | null) => {
      setRdv((currentRdv) => {
        return { ...currentRdv, center };
      });
    },
    [setRdv],
  );

  /**
   * Loading geolocation
   */
  useEffect(() => {
    // Geocoder Service
    const setIntervalId = setInterval(() => {
      if (isGoogleMapsScriptAdded && !geocoderService.current && window.google) {
        geocoderService.current = new google.maps.Geocoder();
      }

      if (geocoderService.current) {
        clearInterval(setIntervalId);
      }
    }, 100);

    if (isGoogleMapsScriptAdded && !geocoderService.current && window.google) {
      geocoderService.current = new google.maps.Geocoder();
    }
  }, [isGoogleMapsScriptAdded]);

  /**
   * Update autocomplete field when address is selected
   */
  useEffect(() => {
    setSelectedPlace(
      rdv.coordInfo
        ? {
            description: rdv.coordInfo.display_name || '',
            place_id: '',
            structured_formatting: {
              main_text: '',
              secondary_text: '',
              main_text_matched_substrings: [],
            },
            matched_substrings: [],
            terms: [],
            types: [],
          }
        : null,
    );
  }, [rdv.coordInfo, selectedAddressType, setSelectedPlace]);

  /**
   * Get nearest centers
   */
  useEffect(() => {
    if (!rdv.coordInfo) {
      return;
    }

    const fetchNearestCenters = async () => {
      if (rdv.prescriptions.length > 0 && rdv.coordInfo) {
        // We don't need to fetch centers if inLabMedicalActs is empty
        if (inLabMedicalActs.length === 0) {
          setNearestCenters([]);
          return;
        }
        try {
          setLoadingNearestCenters(true);
          const nearestCenters = await API.appointments.getNearestCenters(rdv.coordInfo.lat, rdv.coordInfo.lon, inLabMedicalActs);
          if (Array.isArray(nearestCenters) && nearestCenters.length > 0) {
            setNearestCenters(nearestCenters);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingNearestCenters(false);
        }
      }
    };

    fetchNearestCenters();
  }, [rdv.coordInfo, inLabMedicalActs, rdv.prescriptions.length]);

  /**
   * If at least one medical act in lab only, fetch centers
   * If only one center, select it
   */
  useEffect(() => {
    const inLabOnlyActs = rdv.prescriptions.map((prescription) => prescription.analyses.filter((analysis) => analysis.only_center)).flat();
    if (inLabOnlyActs.length > 0) {
      const fetchCenters = async () => {
        try {
          setLoadingCenter(true);
          const centers = await API.appointments.getCentersFromActs(inLabOnlyActs);
          if (Array.isArray(centers) && centers.length === 1) {
            setIsCenterPreselected(true);
            handleSelectCenter(centers[0]);
          } else {
            handleSelectCenter(null);
            setIsCenterPreselected(false);
          }
        } catch (error) {
          console.log(error);
          setIsCenterPreselected(false);
        } finally {
          setLoadingCenter(false);
        }
      };

      fetchCenters();
    } else {
      // If no in-lab only acts, reset center
      handleSelectCenter(null);
      setIsCenterPreselected(false);
    }
  }, [rdv.prescriptions, handleSelectCenter]);

  const centerConflicts = isCenterOnly(rdv) && !!rdv.coordInfo && !loadingNearestCenters && !rdv.center && nearestCenters.length < 2;

  return (
    <Box display='flex' flexDirection='column' justifyContent='center' flex={1}>
      <Box display='flex' flexDirection='column' flex={1}>
        <Box display='flex' flexDirection='column' flex={1} justifyContent='center'>
          <Box my={4} justifyContent='center'>
            <Typography variant='h2' textAlign='center'>
              {t('appointment.book.steps.search.title')}
            </Typography>
          </Box>
          <Box display='flex' flexDirection='column' alignItems='center' flex={1}>
            <Box display='flex' flexDirection='column' alignSelf='center' width={isMobileView ? '100%' : '700px'}>
              <Box display='flex' flexDirection='column' alignItems='center'>
                {loadingCenter ||
                  (!isCenterPreselected && (
                    <BookingLocationAutocomplete
                      selectedPlace={selectedPlace}
                      locationInputRef={locationInputRef}
                      setSelectedPlace={setSelectedPlace}
                      onAddressChange={(address) => selectSearchedAddress(address)}
                      resetPreselectedAddress={() => {
                        setSelectedAddressType(null);
                        setSelectedDate(null);
                        selectSlot('');
                      }}
                    />
                  ))}
                {loadingCenter ||
                  (!isCenterPreselected && (
                  <PatientKnownAddresses
                    patientHomeAddress={patientHomeAddress}
                    patientWorkAddress={patientWorkAddress}
                    selectedAddressType={selectedAddressType}
                    selectPatientKnownAddress={selectPatientKnownAddress}
                  />
                ))}
                {loadingCenter ? (
                  <Box height='300px' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                    <CircularProgress />
                  </Box>
                ) : (
                  ((!isCenterOnly(rdv) && rdv.coordInfo) || rdv.center) && (
                    <BookingSearchSelectedAddress
                      rdv={rdv}
                      selectedDate={selectedDate}
                      memoizedSlots={memoizedSlots}
                      setRdv={setRdv}
                      setSelectedDate={setSelectedDate}
                      selectSlot={selectSlot}
                      setMemoizedSlots={setMemoizedSlots}
                    />
                  )
                )}
              </Box>
              <BookingNavigation handleBack={handleBack} disabledNext={!rdv.slot} handleNext={handleNext} />
            </Box>
            {rdv.coordInfo && nearestCenters.length > 1 && <BookingSearchLaboratories centers={nearestCenters} selectedCenter={rdv.center} selectCenter={handleSelectCenter} selectSlot={selectSlot} />}
          </Box>
        </Box>
      </Box>
      <CustomDialog open={loadingStrictAddressDialog}>
        <Box display='flex' flexDirection='column' alignItems='center' gap={2} p={4}>
          <Typography variant='h2' textAlign='center'>
            {t(`appointment.book.steps.search.not_strict_address.title`)}
          </Typography>
          <Typography variant='body1'>
            <Nl2br text={t(`appointment.book.steps.search.not_strict_address.description`)} />
          </Typography>

          <Box display='flex' gap={2} flexDirection={isMobileView ? 'column' : 'row'}>
            <Button
              variant='contained'
              onClick={() => {
                setLoadingStrictAddressDialog(false);
                if (locationInputRef.current) {
                  locationInputRef.current.focus();
                  locationInputRef.current.selectionStart = 0;
                  locationInputRef.current.selectionEnd = 0;
                }
              }}
            >
              {t('appointment.book.steps.search.not_strict_address.action.redo')}
            </Button>
            <Button variant='outlined' onClick={() => setLoadingStrictAddressDialog(false)}>
              {t('appointment.book.steps.search.not_strict_address.action.bypass')}
            </Button>
          </Box>
        </Box>
      </CustomDialog>
      <CustomDialog open={!loadingCenter && centerConflicts}>
        <Box display='flex' flexDirection='column' alignItems='center' gap={2} p={4}>
          <Typography variant='h2' textAlign='center'>
            {t('appointment.book.steps.search.centers.conflict.title')}
          </Typography>
          <Typography variant='body1'>
            {t('appointment.book.steps.search.centers.conflict.description')}
            <List>{rdv.prescriptions.map((prescription) => prescription.analyses.map((analysis) => <ListItem key={analysis.id}>{analysis.name}</ListItem>))}</List>
          </Typography>
          <Box display='flex' gap={2} flexDirection={isMobileView ? 'column' : 'row'}>
            <Button variant='contained' onClick={handleBack}>
              {t('appointment.book.steps.search.centers.conflict.button')}
            </Button>
          </Box>
        </Box>
      </CustomDialog>
    </Box>
  );
};

export default BookingSearchStep;
