import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import CustomDateCalendar from 'components/custom-date-calendar';
import { useIsMobileView } from 'hooks';
import { Rdv } from 'models/appointment.model';
import { useTranslation } from 'react-i18next';
import { formatDate, getLastDayOfMonth, getSlotsKey } from 'utils/func/Appointment.func';
import { MemoizedSlots } from '..';
import { useCallback } from 'react';

type BookingSearchDatePickerProps = {
  rdv: Rdv;
  selectedDate: Date | null;
  startDate: Date;
  endDate: Date;
  memoizedSlots: MemoizedSlots;
  loadingSlots: boolean;
  slotsOpen?: boolean;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  setSelectedDate: (date: Date | null) => void;
  setSlotsOpen?: (open: boolean) => void;
  setMemoizedSlots: (memoizedSlots: MemoizedSlots) => void;
};

const BookingSearchDatePicker: React.FC<BookingSearchDatePickerProps> = ({
  rdv,
  selectedDate,
  startDate,
  endDate,
  memoizedSlots,
  loadingSlots,
  slotsOpen = true,
  setStartDate,
  setEndDate,
  setSelectedDate,
  setSlotsOpen = false,
}) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  const handleShouldDisableDate = useCallback(
    (day: Date) => {
      const availableSlots = memoizedSlots.get(getSlotsKey(startDate, endDate, rdv));
      // No available slots for this month
      if (!availableSlots) {
        return true;
      }

      // No available slots for this day
      if (!(formatDate(day) in availableSlots)) {
        return true;
      }

      // All slots for that day are full or closed
      if (availableSlots[formatDate(day)].filter((slot) => slot.nbrSlot > 0 && !slot.closed).length < 1) {
        return true;
      }

      // If at least on slot is still available, we can select the day
      return false;
    },
    [memoizedSlots, startDate, endDate, rdv],
  );

  return (
    <Box display='flex' flexDirection='column' flex='1 1 50%' sx={{ boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A', borderRadius: '10px' }}>
      <Box flex={1}>
        <CustomDateCalendar
          value={selectedDate}
          onChange={(newDate) => {
            setSelectedDate(newDate || new Date());
            if (typeof setSlotsOpen === 'function') {
              setSlotsOpen(true);
            }
          }}
          minDate={new Date()}
          onMonthChange={(month) => {
            setStartDate(month);
            setEndDate(getLastDayOfMonth(month));
          }}
          shouldDisableDate={handleShouldDisableDate}
          loading={loadingSlots}
        />
      </Box>
      {!isMobileView && typeof setSlotsOpen === 'function' && (
        <Box display='flex' alignItems='center' justifyContent='space-between' width='100%' padding={1}>
          <Button
            variant='link'
            onClick={() => setSlotsOpen(false)}
            sx={{
              opacity: slotsOpen ? 1 : 0,
              whiteSpace: 'nowrap',
              transition: 'opacity 0.5s ease-in-out',
              borderWidth: '2px',
            }}
          >
            <ChevronLeft /> {t('appointment.book.steps.search.carousel.show_address')}
          </Button>
          {selectedDate && (
            <Button
              variant='link'
              onClick={() => setSlotsOpen(true)}
              sx={{
                opacity: slotsOpen ? 0 : 1,
                whiteSpace: 'nowrap',
                transition: 'opacity 0.5s ease-in-out',
                borderWidth: '2px',
              }}
            >
              {t('appointment.book.steps.search.carousel.show_slots')} <ChevronRight />
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default BookingSearchDatePicker;
