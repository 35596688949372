import { Theme } from '@emotion/react';
import { Close, Female, Male } from '@mui/icons-material';
import { Box, SxProps, Typography, IconButton } from '@mui/material';
import ToggleButton from 'components/buttons/toggle-button';
import CustomDatePicker from 'components/custom-date-calendar/custom-date-picker';
import CustomTextField from 'components/custom-textfield';
import { useIsMobileView } from 'hooks';
import MuiPhoneNumber from 'material-ui-phone-number';
import { RdvPatient, RdvPrescription } from 'models/appointment.model';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isRequiredFields } from 'utils/func/Appointment.func';
import { getAppointmentTempPatientName } from 'utils/func/Person.func';
import { PatientsInfoError } from './Booking.patients.info.step';
import { cnsValidator } from '../../../../utils/func/CnsValidator.func';
import { isGSMNumber } from 'utils/func/Phone.func';

type BookingPatientInfoCardProps = {
  index: number;
  prescription: RdvPrescription;
  errors: PatientsInfoError | undefined;
  enableRemove: boolean;
  setPrescription: (prescription: RdvPrescription) => void;
  onRemovePrescription: (prescription: RdvPrescription) => void;
  // @see#22 Use these callback to activate/deactivate the next button
  onValid: () => void;
};

const BookingPatientInfoCard: FC<BookingPatientInfoCardProps> = ({
  index,
  prescription,
  errors,
  enableRemove,
  setPrescription,
  onRemovePrescription,
  // @see#22 Use these callback to activate/deactivate the next button
  onValid,
}) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  const handleSelectGender = (gender: 'male' | 'female') => {
    setPrescription({ ...prescription, patient: { ...prescription.patient, gender } });
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPrescription({ ...prescription, patient: { ...prescription.patient, [event.target.name]: event.target.value } });
  };

  // @see#22 Using Set as State require new Object assignation
  const [fieldsBlur, setFieldsBlur] = useState<Set<string>>(new Set<string>());

  // When left a field, verify requirements
  const handleTextFieldBlur = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // @see#22 Using Set as State require new Object assignation
    setFieldsBlur((currentFieldsBlur) => {
      return new Set(currentFieldsBlur).add(event.target.name);
    });
  };

  const handleDateFieldChange = (date: Date | null) => {
    setPrescription({ ...prescription, patient: { ...prescription.patient, birthdate: date ?? '' } });
  };

  // On close, add birthdate to verification
  const handleDateFieldClose = () => {
    // @see#22 Using Set as State require new Object assignation
    setFieldsBlur((currentFieldsBlur) => {
      return new Set(currentFieldsBlur).add('birthdate');
    });
  };

  const handleDeletePrescription = (prescription: RdvPrescription) => {
    onRemovePrescription(prescription);
  };

  const hasFieldError = (field: keyof RdvPatient) => errors?.errors.some((error) => error.field === field);
  const sx: SxProps<Theme> = {
    boxShadow: 'inset 0px 1px 1px #F1F1EF, 0px 8px 16px #0000000A',
    borderRadius: '16px',
  };

  const isConnected = !!prescription.patient.id;

  // Initialize error handler
  if (errors === undefined) {
    errors = {
      prescriptionId: '',
      types: [],
      errors: [],
    };
  }

  // Verify email
  if (fieldsBlur.has('verifyemail')) {
    if (!prescription.patient?.verifyemail) {
      errors?.errors.push({ field: 'verifyemail' });
      errors?.types.push('required');
    } else if (prescription.patient?.email && prescription.patient?.email !== prescription.patient?.verifyemail) {
      errors?.errors.push({ field: 'email' }, { field: 'verifyemail' });
      errors?.types.push('email');
    } else {
      // @see#22 Using Set as State require new Object assignation
      // Remove from state
      setFieldsBlur((currentFieldsBlur) => {
        currentFieldsBlur = new Set(currentFieldsBlur);
        currentFieldsBlur.delete('verifyemail');
        return currentFieldsBlur;
      });
    }
  }

  // Verify CNS
  if (fieldsBlur.has('cns')) {
    if (prescription.patient?.cns && prescription.patient?.birthdate && !cnsValidator(prescription.patient?.cns, prescription.patient?.birthdate.toString())) {
      errors?.errors.push({ field: 'birthdate' }, { field: 'cns' });
      errors?.types.push('cns');
    } else {
      // @see#22 Using Set as State require new Object assignation
      // Remove from state
      setFieldsBlur((currentFieldsBlur) => {
        currentFieldsBlur = new Set(currentFieldsBlur);
        currentFieldsBlur.delete('cns');
        return currentFieldsBlur;
      });
    }
  }

  // Verify mobile phone
  if (fieldsBlur.has('phone')) {
    if (!prescription.patient?.phone) {
      errors?.errors.push({ field: 'phone' });
      errors?.types.push('required');
    } else if (!isGSMNumber(prescription.patient?.phone)) {
      errors?.errors.push({ field: 'phone' });
      errors?.types.push('mobilephone');
    } else {
      // @see#22 Using Set as State require new Object assignation
      // Remove from state
      setFieldsBlur((currentFieldsBlur) => {
        currentFieldsBlur = new Set(currentFieldsBlur);
        currentFieldsBlur.delete('phone');
        return currentFieldsBlur;
      });
    }
  }

  fieldsBlur.forEach((field) => {
    if (new Set(['verifyemail', 'cns', 'phone']).has(field)) {
      // These fields have already been validated
    } else {
      if (isRequiredFields(field as keyof RdvPatient, isConnected) && !prescription.patient?.[field as keyof RdvPatient]) {
        errors?.errors.push({ field: field });
        errors?.types.push('required');
      } else {
        // @see#22 Using Set as State require new Object assignation
        // Remove from state
        setFieldsBlur((currentFieldsBlur) => {
          currentFieldsBlur = new Set(currentFieldsBlur);
          currentFieldsBlur.delete(field);
          return currentFieldsBlur;
        });
      }
    }
  });

  if (errors && errors.errors.length > 0) {
    sx.border = '1px solid red';
  } else {
    // @see#22 Use callback to activate/deactivate button
    onValid();
  }

  return (
    <Box display='flex' flexDirection='column' gap={3} sx={sx} p={2}>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Typography variant='h3'>{getAppointmentTempPatientName(prescription.patient, index, t)}</Typography>
        </Box>
        {enableRemove && (
          <Box>
            <IconButton onClick={() => handleDeletePrescription(prescription)}>
              <Close />
            </IconButton>
          </Box>
        )}
      </Box>
      {!isConnected && (
        <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} alignItems={isMobileView ? 'initial' : 'center'} gap={2}>
          <Box display='flex' gap={2}>
            <Box>
              <ToggleButton selected={prescription.patient.gender === 'female'} hasError={hasFieldError('gender')} onToggle={() => handleSelectGender('female')}>
                <Female /> {t('appointment.book.steps.patients_info.form_labels.female')}
                {'\u00A0'}*
              </ToggleButton>
            </Box>
            <Box>
              <ToggleButton selected={prescription.patient.gender === 'male'} hasError={hasFieldError('gender')} onToggle={() => handleSelectGender('male')}>
                <Male /> {t('appointment.book.steps.patients_info.form_labels.male')}
                {'\u00A0'}*
              </ToggleButton>
            </Box>
          </Box>
        </Box>
      )}
      {!isConnected && (
        <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} gap={2}>
          <Box flex={1}>
            <CustomTextField
              fullWidth
              name='firstname'
              label={t('appointment.book.steps.patients_info.form_labels.first_name')}
              required={isRequiredFields('firstname', isConnected)}
              error={hasFieldError('firstname')}
              value={prescription.patient.firstname}
              onChange={handleTextFieldChange}
              onBlur={handleTextFieldBlur}
            />
          </Box>
          <Box flex={1}>
            <CustomTextField
              fullWidth
              name='birthname'
              label={t('appointment.book.steps.patients_info.form_labels.birth_name')}
              required={isRequiredFields('birthname', isConnected)}
              error={hasFieldError('birthname')}
              value={prescription.patient.birthname}
              onChange={handleTextFieldChange}
              onBlur={handleTextFieldBlur}
            />
          </Box>
          <Box flex={1}>
            <CustomTextField
              fullWidth
              name='lastname'
              label={t('appointment.book.steps.patients_info.form_labels.last_name')}
              required={isRequiredFields('lastname', isConnected)}
              error={hasFieldError('lastname')}
              value={prescription.patient.lastname}
              onChange={handleTextFieldChange}
              onBlur={handleTextFieldBlur}
            />
          </Box>
        </Box>
      )}
      {!isConnected && (
        <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} gap={2}>
          <Box flex={1}>
            <CustomDatePicker
              fullWidth
              name='birthdate'
              label={t('appointment.book.steps.patients_info.form_labels.birth_date')}
              required={isRequiredFields('birthdate', isConnected)}
              error={hasFieldError('birthdate')}
              value={prescription.patient.birthdate ? new Date(prescription.patient.birthdate) : null}
              onChange={handleDateFieldChange}
              onClose={handleDateFieldClose}
              slotProps={{
                textField: {
                  /* helperText: t('preregistration.myData.form.helperText.birthdate'), */
                  inputProps: {
                    onBlur: handleTextFieldBlur,
                  },
                },
              }}
            />
          </Box>
          <Box flex={1}>
            <CustomTextField
              fullWidth
              name='cns'
              label={t('appointment.book.steps.patients_info.form_labels.cns')}
              required={isRequiredFields('cns', isConnected)}
              value={prescription.patient.cns}
              error={hasFieldError('cns')}
              helperText={errors?.types.includes('cns') ? t('preregistration.myData.form.error.invalidCNS') : null}
              onChange={handleTextFieldChange}
              onBlur={handleTextFieldBlur}
              FormHelperTextProps={{ sx: { maxWidth: '400px' } }}
            />
          </Box>
          <Box flex={1} display={isMobileView ? 'none' : 'block'}></Box>
        </Box>
      )}
      <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} gap={2}>
        <Box flex={1}>
          <CustomTextField
            fullWidth
            name='email'
            type='email'
            required={isRequiredFields('email', isConnected)}
            error={hasFieldError('email')}
            label={t('appointment.book.steps.patients_info.form_labels.email')}
            value={prescription.patient.email}
            helperText={errors?.types.includes('email') ? t('appointment.book.steps.patients_info.error_message.email') : t('preregistration.myData.form.helperText.email')}
            onChange={handleTextFieldChange}
            onBlur={handleTextFieldBlur}
          />
        </Box>
        <Box flex={1}>
          <CustomTextField
            fullWidth
            name='verifyemail'
            type='email'
            label={t('appointment.book.steps.patients_info.form_labels.verifyemail')}
            required={isRequiredFields('verifyemail', isConnected)}
            error={hasFieldError('verifyemail')}
            value={prescription.patient.verifyemail}
            onChange={handleTextFieldChange}
            onBlur={handleTextFieldBlur}
          />
        </Box>
      </Box>
      <Box display='flex' flexDirection={isMobileView ? 'column' : 'row'} gap={2}>
        <Box flex={1}>
          <MuiPhoneNumber
            label={t('appointment.book.steps.patients_info.form_labels.phone')}
            name='phone'
            fullWidth
            InputLabelProps={{
              sx: {
                fontSize: '0.8rem',
                lineHeight: '2em',
                fontWeight: '500',
                color: '#808080',
                px: 1,
                '& .MuiFormLabel-asterisk': {
                  color: 'currentColor',
                },
              },
            }}
            InputProps={{
              sx: { borderColor: '#212121', borderWidth: '2px' },
            }}
            autoFormat={false} // @see #6 Disable autoformatting since it's not valid for LU
            defaultCountry={'lu'}
            preferredCountries={['lu', 'fr', 'be', 'de', 'pt', 'es', 'nl', 'uk', 'it']}
            countryCodeEditable={true}
            variant='filled'
            required={isRequiredFields('phone', isConnected)}
            error={hasFieldError('phone')}
            value={prescription.patient.phone}
            helperText={t('preregistration.myData.form.helperText.mobile_phone')}
            onChange={(parameter) => {
              if (typeof parameter === 'string') {
                setPrescription({ ...prescription, patient: { ...prescription.patient, phone: parameter } });
              } else {
                handleTextFieldChange(parameter);
              }
            }}
            onBlur={handleTextFieldBlur}
          />
        </Box>
        <Box flex={1} display={isMobileView ? 'none' : 'block'}></Box>
      </Box>
    </Box>
  );
};

export default BookingPatientInfoCard;
