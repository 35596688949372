import { Box, Button } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type BookingNavigationProps = {
  showBack?: boolean;
  disabledBack?: boolean;
  showNext?: boolean;
  disabledNext?: boolean;
  backElement?: ReactNode;
  nextElement?: ReactNode;
  fixed?: boolean;
  positionBack?: string;
  positionNext?: string;
  handleBack?: () => void;
  handleNext?: () => void;
};

const BookingNavigation: FC<BookingNavigationProps> = ({
  showBack = true,
  disabledBack = false,
  showNext = true,
  disabledNext = false,
  backElement,
  nextElement,
  fixed = true,
  positionBack,
  positionNext,
  handleBack,
  handleNext,
}) => {
  const { t } = useTranslation();
  const isMobileView = useIsMobileView();

  const justifyContent = showBack && showNext ? 'space-between' : showNext ? 'flex-end' : 'flex-start';

  const sxBack = !fixed || isMobileView ? {} : { position: 'fixed', top: positionBack ?? '50%', left: '10%' };
  const sxNext = !fixed || isMobileView ? {} : { position: 'fixed', top: positionNext ?? '50%', right: '10%' };

  return (
    <Box display='flex' justifyContent={justifyContent} paddingY={2}>
      {showBack && (
        <Button variant='outlined' onClick={handleBack} disabled={disabledBack} sx={sxBack}>
          {backElement ?? t('appointment.book.action_buttons.previous')}
        </Button>
      )}
      {showNext && (
        <Button variant='contained' onClick={handleNext} disabled={disabledNext} sx={sxNext}>
          {nextElement ?? t('appointment.book.action_buttons.next')}
        </Button>
      )}
    </Box>
  );
};

export default BookingNavigation;
