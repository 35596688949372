import { Box, Typography } from '@mui/material';
import RoundButton from 'components/buttons/round-button';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createAnonymousPatientsFromNumber } from 'utils/func/Appointment.func';
import { Props } from '../index';
import BookingNavigation from './Booking.navigation';

const MIN_AVAILABLE_PATIENT_NUMBER = 5;
const MAX_AVAILABLE_PATIENT_NUMBER: number | undefined = undefined;
const PATIENT_NUMBER_STEP = 3;

const BookingPatientsNumber: FC<Props> = ({ rdv, setRdv, setIntroductionStep }) => {
  const { t } = useTranslation();
  const [availablePatientNumber, setAvailablePatientNumber] = useState(
    // Make sure that the available patient number is always a multiple of the step
    Math.max(MIN_AVAILABLE_PATIENT_NUMBER, MIN_AVAILABLE_PATIENT_NUMBER + Math.ceil((rdv.prescriptions.length - MIN_AVAILABLE_PATIENT_NUMBER) / PATIENT_NUMBER_STEP) * PATIENT_NUMBER_STEP),
  );

  const handleLess = () => {
    setAvailablePatientNumber((currentAvailablePatientNumber) => Math.max(MIN_AVAILABLE_PATIENT_NUMBER, currentAvailablePatientNumber - PATIENT_NUMBER_STEP));
  };

  const handleMore = () => {
    setAvailablePatientNumber((currentAvailablePatientNumber) => {
      let newPatientNumber = currentAvailablePatientNumber + PATIENT_NUMBER_STEP;
      if (MAX_AVAILABLE_PATIENT_NUMBER) {
        newPatientNumber = Math.min(newPatientNumber, MAX_AVAILABLE_PATIENT_NUMBER);
      }
      return newPatientNumber;
    });
  };

  const handleSetPatientNumber = (patientNumber: number) => {
    const newPrescriptions = [...rdv.prescriptions];
    const currentPrescriptions = newPrescriptions.filter((prescription) => !prescription.patient?.id).length;

    setRdv((currentRdv) => {
      const newRdv = { ...currentRdv };

      if (patientNumber > currentPrescriptions) {
        newRdv.prescriptions = [...newPrescriptions, ...createAnonymousPatientsFromNumber(patientNumber - currentPrescriptions)];
        return newRdv;
      }
      return { ...newRdv, prescriptions: newPrescriptions.slice(0, patientNumber) };
    });

    setIntroductionStep('choose-acts');
  };

  useEffect(() => {
    if (rdv.prescriptions.length && rdv.prescriptions.length > availablePatientNumber) {
      setRdv((currentRdv) => ({
        ...currentRdv,
        prescriptions: [],
      }));
    }
  }, [availablePatientNumber, rdv.prescriptions.length, setRdv]);

  return (
    <Box display='flex' flexDirection='column' flex={1}>
      <Box display='flex' flexDirection='column' flex={1} justifyContent='center' alignSelf='center' maxWidth='700px'>
        <Box mb={4} justifyContent='center' mt={4}>
          <Typography variant='h2' textAlign='center'>
            {t('appointment.book.steps.introduction.persons.title')}
          </Typography>
        </Box>
        <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' gap={2} flexWrap='wrap'>
          <RoundButton visible={availablePatientNumber > MIN_AVAILABLE_PATIENT_NUMBER} onClick={handleLess}>
            {t('appointment.book.steps.introduction.buttons.less')}
          </RoundButton>
          {Array.from({ length: availablePatientNumber }, (_, i) => i + 1).map((number) => (
            <RoundButton key={number} selected={number === rdv.prescriptions.length} onClick={() => handleSetPatientNumber(number)}>
              {number.toString()}
            </RoundButton>
          ))}
          <RoundButton visible={!MAX_AVAILABLE_PATIENT_NUMBER || availablePatientNumber < MAX_AVAILABLE_PATIENT_NUMBER} onClick={handleMore}>
            {t('appointment.book.steps.introduction.buttons.more')}
          </RoundButton>
        </Box>
        <BookingNavigation showBack={false} handleNext={() => setIntroductionStep('choose-acts')} disabledNext={rdv.prescriptions.length < 1} showNext={rdv.prescriptions.length > 0} />
      </Box>
    </Box>
  );
};

export default BookingPatientsNumber;
